import React, { Component } from "react";

class DayNightModeComponent extends Component
{
    state = {
        isNightMode: false, // You can manage the day/night mode state here.
    };

    isJSONString(str)
    {
        try
        {
            JSON.parse(str);
            return true;
        } catch (error)
        {
            return false;
        }
    }

    componentDidMount()
    {
        let parsedCookie = this.isJSONString(document.cookie) ? JSON.parse(document.cookie) : {}
        if (parsedCookie.isNightMode === true)
            document.body.classList.add("dark")
    }

    // Function to toggle day/night mode
    toggleDayNightMode = () =>
    {
        this.setState((prevState) => ({
            isNightMode: !prevState.isNightMode,
        }));
        console.log(this.state.isNightMode);
        let parsedCookie = this.isJSONString(document.cookie) ? JSON.parse(document.cookie) : {}
        parsedCookie.isNightMode = this.state.isNightMode
        document.cookie = JSON.stringify(parsedCookie)
        if (this.state.isNightMode === true)
            document.body.classList.add("dark")
        else
            document.body.classList.remove("dark")
        // You can add code here to change the theme or perform other actions.
    };

    render()
    {
        const { isNightMode } = this.state;

        return (
            <div>
                <button onClick={this.toggleDayNightMode}>
                    {isNightMode ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M12 2c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8z" />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                        >
                            <path d="M12 2c-.487 0-.947.063-1.408.145 2.552.284 4.897 1.209 6.923 2.834a11.995 11.995 0 0 0-6.746-2.074zm-2.023 1.977a11.95 11.95 0 0 0-3.174 4.1c-.228.453-.428.918-.606 1.393a12.04 12.04 0 0 0-2.957 2.57 11.958 11.958 0 0 0-2.073 6.742c.475-.178.94-.378 1.392-.605a11.966 11.966 0 0 0 4.102-3.176zm-.39 1.463a10.036 10.036 0 0 1 4.367 4.368 10.034 10.034 0 0 1-4.368 4.368 10.038 10.038 0 0 1-4.368-4.368 10.038 10.038 0 0 1 4.368-4.368z" />
                        </svg>
                    )}
                </button>
            </div>
        );
    }
}

export default DayNightModeComponent;