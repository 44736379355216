import { Component } from "react";
import { Button } from "react-bootstrap";
import { postData, getData } from "../../helpers/postData";


class TestTemplaterPage extends Component
{
    state = {};


    async payload()
    {
        console.log("payload");

        let response = await postData('/templaterpayload', {});
        console.log("RESPONSE", response);

        let arraybuffer = await response.arrayBuffer();

        const blob = new Blob([arraybuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const downloadUrl = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'trade.docx';
        link.click();
    }

    render()
    {
        return (
            <div>
                test templater page

                <Button onClick={() => this.payload()}>Push the ff button</Button>
            </div>
        )
    }
}

export default TestTemplaterPage;