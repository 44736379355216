import
{
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Link,
  HashRouter
} from "react-router-dom";

import { Component } from 'react';
import logo from './logo.svg';

import FourZeroFour from "./pages/fourzerofour/FourZeroFour";
import FFCalender from "./pages/calender/FFCalender";
import UserDash from "./pages/dash/UserDash";

import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { postData } from "./helpers/postData";
import AdvertisementBox from "./pages/components/AdvertisementBox";
import PlatoonBuilder from "./pages/platoonbuilder/PlatoonBuilder";
import PlatoonRotation from "./pages/platoonrotation/PlatoonRotation";
import ModifyUsers from "./pages/usermanagement/ModifyUsers";
import TestPage from "./pages/test/Test";
import FurloughsPage from "./pages/furloughs/FurloughsPage";
import TestPageThree from "./pages/test/TestThree";
import RoleEditor from "./pages/roleeditor/RoleEditor";
import AdminAuditPage from "./pages/audit/AdminAuditPage";
import TestTemplaterPage from "./pages/test/TestTemplater";
import ConfirmedTradesPage from "./pages/confirmedtrades/ConfirmedTrades";
import DayNightModeComponent from "./pages/app/DayNightMode";

import './App.css';
import FurloughManagerPage from "./pages/furloughs/FurloughManager";
import FFCalendarPage from "./pages/calender/FFCalendarPage";
import RegisterPage from "./pages/register/RegisterPage";
import CalendarBuilderPage from "./pages/calendarbuilder/CalendarBuilder";


class App extends Component
{
  state = {
    message: "Getting data from server..",
    navbarExtended: false,
    user: null,
    fetchedUser: false
  }


  constructor(props)
  {
    super(props)
    console.log("app constructor", props);
  }

  clickedNavbarItem()
  {
    this.setState({ navbarExtended: false });
  }

  toggleNavbar()
  {
    this.setState({ navbarExtended: !this.state.navbarExtended });
  }

  async fetchSession()
  {
    var request = await postData('/getsession', {});
    var json = await request.json();
    console.log("app fetch session", json);
    if (json)
      this.setUser(json)
    else
      this.setState({ fetchedUser: true });
  }

  async logoutUser()
  {
    await postData('/logout', {})
    this.setState({ user: null, navbarExtended: false })
  }

  setUser(user)
  {
    console.log("app: i grabbed a user!", user)

    //parsing
    if (user)
    {
      if (user.workingDays)
        user.workingDays.forEach(wd =>
        {
          wd.date = new Date(wd.date)
        })
    }

    // console.log("app: i grabbed a user POST MOD!", user)

    this.setState({ user: user, fetchedUser: true });
  }

  componentDidMount()
  {
    this.fetchSession();
  }

  buildPrivilegedDropdown()
  {
    const { user } = this.state;
    if (!user)
      return null;
    if (!user.admin)
      return null;
    console.log("passed admin check")

    return (
      <NavDropdown title="Admin Menu" href="#" id="basic-nav-dropdown">
        <NavDropdown.Item as={Link} to="/platoonbuilder" onClick={() => this.clickedNavbarItem()}>Platoon Builder</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/platoonrotation" onClick={() => this.clickedNavbarItem()}>Platoon Rotations</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/roleeditor" onClick={() => this.clickedNavbarItem()}>Role Editor</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/usermanagement" onClick={() => this.clickedNavbarItem()}>User Management</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/adminaudit" onClick={() => this.clickedNavbarItem()}>Admin Auditing</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/furloughmanager" onClick={() => this.clickedNavbarItem()}>Furlough Manager</NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/test" onClick={() => this.clickedNavbarItem()}>Test Page</NavDropdown.Item>

        <NavDropdown.Divider />
        <NavDropdown.Item onClick={() => this.clickedNavbarItem()} href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item onClick={() => this.clickedNavbarItem()} href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Item onClick={() => this.clickedNavbarItem()} href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown>
    )
  }

  buildTopNavBar()
  {
    const { navbarExtended, user } = this.state;
    console.log("user state", user)

    return (
      <Navbar expand="lg" expanded={navbarExtended} sticky="top">
        <Container>
          <Navbar.Brand as={Link} to="/" onClick={() => this.clickedNavbarItem()}>FireFast</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => this.toggleNavbar()} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/" onClick={() => this.clickedNavbarItem()} href="#">Home</Nav.Link>
              <Nav.Link as={Link} to="/calender" onClick={() => this.clickedNavbarItem()} href="#">Calender</Nav.Link>
              <Nav.Link as={Link} to="/furloughs" onClick={() => this.clickedNavbarItem()} href="#">Furloughs</Nav.Link>
              {this.buildPrivilegedDropdown()}
            </Nav>


            <Nav>
              <DayNightModeComponent/>
              {
                user ? (
                  <NavDropdown title={user.username} id="basic-nav-dropdown">
                    <NavDropdown.Item as={Link} to="/dash" onClick={() => this.clickedNavbarItem()}>Dash</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/confirmedtrades" onClick={() => this.clickedNavbarItem()}>Confirmed Trades</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2" onClick={() => this.clickedNavbarItem()}>Another action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3" onClick={() => this.clickedNavbarItem()}>Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => this.logoutUser()}>Logout</NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <Nav.Link as={Link} to="/dash" href="#" onClick={() => this.clickedNavbarItem()}>{user ? user.username : "User"}</Nav.Link>
                )
              }
            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }

  render()
  {
    const { fetchedUser } = this.state;

    return (
      <div style={{width: "100%", height: "100%"}}>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="viewport" content="viewport-fit=cover, user-scalable=no, width=device-width, initial-scale=1, maximum-scale=1" />
        {
          fetchedUser ? (
            <BrowserRouter>
              {
                this.buildTopNavBar()
              }
              <AdvertisementBox />

              <Routes>
                <Route path="/" element={<div style={{width: "100%", height: "100%"}}><p>Home Page</p></div>} />
                <Route path="/calender" element={<FFCalender getUser={() => this.state.user} />} />
                <Route path="/dash" element={<UserDash setUser={(user) => this.setUser(user)} getUser={() => this.state.user} />} />
                <Route path="/platoonbuilder" element={<PlatoonBuilder getUser={() => this.state.user} />} />
                <Route path="/platoonrotation" element={<PlatoonRotation getUser={() => this.state.user} />} />
                <Route path="/usermanagement" element={<ModifyUsers getUser={() => this.state.user} />} />
                <Route path="/roleeditor" element={<RoleEditor getUser={() => this.state.user} />} />
                <Route path="/adminaudit" element={<AdminAuditPage getUser={() => this.state.user} />} />
                <Route path="/furloughs" element={<FurloughsPage getUser={() => this.state.user} />} />
                <Route path="/test" element={<TestPage getUser={() => this.state.user} />} />
                <Route path="/test3" element={<TestPageThree getUser={() => this.state.user} />} />
                <Route path="/testtemplater" element={<TestTemplaterPage getUser={() => this.state.user} />} />
                <Route path="/confirmedtrades" element={<ConfirmedTradesPage getUser={() => this.state.user} />} />
                <Route path="/furloughmanager" element={<FurloughManagerPage getUser={() => this.state.user} />} />
                <Route path="/ctest" element={<FFCalendarPage getUser={() => this.state.user} />} />
                <Route path="/register" element={<RegisterPage getUser={() => this.state.user} />} />
                <Route path="/managecalendars" element={<CalendarBuilderPage getUser={() => this.state.user} />} />

                <Route path="*" element={<FourZeroFour />} />
              </Routes>
            </BrowserRouter>
          ) : (
            <div>
              Loading...
            </div>
          )
        }

      </div>
    )
  }
}

export default App;


// function App()
// {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
