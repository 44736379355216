import { Component } from "react";
import FFCalendarTable from "../../../shared/components/FFCalendarTable";
import { getHolidaysForMonth } from "../../../helpers/Holidays";
import { getLastDayOfMonth, distanceInDaysBetweenDates, getLetterCombination } from "../../../helpers/CalendarUtils";

class FFProgrammedCalendar extends Component {
    getUser;
    getParentState;
    seekCurrentMonthOfCalender;
    showWorkDayOnModal;

    constructor(props) {
        super(props)
        this.getUser = props.getUser;
        this.getParentState = props.getParentState;
        this.seekCurrentMonthOfCalender = props.seekCurrentMonthOfCalender;
        this.showWorkDayOnModal = props.showWorkDayOnModal;
    }

    //CLICK EVENT FOR CALENDAR CELLS
    clickHandler(e, i) {
        console.log("custom click handler", e, i);

        if (e.placeholder)
            return;

        let chosenDayIndex = i - this.getPlaceholderOffset();
        console.log("placeholderoffset", this.getPlaceholderOffset());

        //solving the day they clicked (as a date)
        const { date } = this.getParentState();
        console.log("parent date", date);
        let wantedDate = new Date(date.getTime());
        wantedDate.setHours(0, 0, 0, 0);
        wantedDate.setDate(chosenDayIndex + 1); //CHECK BACK ON THIS, MAY BE MISALIGNED LATER

        console.log("WantedDate", wantedDate);
        this.showWorkDayOnModal(wantedDate);


        console.log("custom click handler2222", chosenDayIndex);
    }

    //NAVIGATE BUTTONS EVENT FOR CALENDAR
    seekHandler(left) //boolean, left is true, right is false
    {
        console.log("custom seek handler", left);
        this.seekCurrentMonthOfCalender(!left)
    }

    //figure out which indexes that are placeholders
    getPlaceholderOffset() {
        const { date } = this.getParentState();
        let clonedDate = new Date(date.getTime());
        clonedDate.setDate(1)
        return clonedDate.getDay();
    }

    //makes the cell data from parent components state for the FFCalendarTable
    buildCellData() {
        // const { workingDays } = this.getUser();
        const { platoonOrigin, emsOrigin, platoons, trades, myRequestedFurloughs, date } = this.getParentState();
        // console.log("ffcalender buildcalenderobjects workingdays", workingDays);
        // workingDays.forEach(wd => console.log("loop", wd.date.toISOString().split("T")[0]));

        var copiedDate = new Date(date.getTime());
        copiedDate.setDate(1); //setting it to first of the month
        copiedDate.setHours(0, 0, 0, 0); //setting it to midnight

        var daysInMonth = getLastDayOfMonth(copiedDate);

        var days = [];

        var holidays = getHolidaysForMonth(copiedDate.getMonth(), copiedDate.getFullYear());

        var shownCFDWelcomeMessage = false;

        //get trade bank trades for this month
        console.log("buildcalender: trades:", trades);

        //this is padding for dates where the first day of the month isn't sunday.
        var dayOffset = copiedDate.getDay();
        for (let i = 0; i < dayOffset; i++) {
            if (!shownCFDWelcomeMessage) {
                days.push(this.placeholderObj("ALL CFD members\nare welcome to join\nour credit union"));
                shownCFDWelcomeMessage = true;
            }
            else {
                let d8 = new Date(copiedDate.getTime());
                d8.setDate(1);
                d8.setDate(d8.getDate() - (dayOffset - i));
                days.push(this.placeholderObj(d8.getDate() + ""));
            }


        }

        //start making our days into objects
        for (let i = 0; i < daysInMonth; i++) {
            // const workingDay = workingDays.findIndex(wd => this.getRawDateStr(wd.date) === this.getRawDateStr(date)); //old
            // console.log("calender day construction: ", i, workingDay);

            var messageArr = [];

            var asDate = new Date(copiedDate.getTime());
            asDate.setDate(asDate.getDate() + i);

            let distanceBetweenPlatoonOrigin = distanceInDaysBetweenDates(platoonOrigin, asDate);
            let distanceBetweenEmsOrigin = distanceInDaysBetweenDates(emsOrigin, asDate);
            if (platoons.length > 0 && distanceBetweenPlatoonOrigin >= 0 && distanceBetweenEmsOrigin >= 0) {
                //platoon calculation
                let dailyDay = getLetterCombination((Math.floor(distanceBetweenPlatoonOrigin / 3) % 5) + 1);
                let platoonLoop = platoons[distanceBetweenPlatoonOrigin % 3];
                messageArr.push({
                    message: `${asDate.getDate()}-${dailyDay}`,
                    color: platoonLoop.color,
                });

                //ems calculation
                let emsLoop = Math.floor(distanceBetweenEmsOrigin % 4);
                messageArr.push({
                    message: `EMS-${emsLoop + 1}`,
                    color: 'black',
                });
            }
            else {
                messageArr.push({
                    message: `${asDate.getDate()}`,
                    color: 'black',
                });
            }


            //handling holidays for this date
            var holidayToday = holidays[i + 1];
            if (holidayToday) {
                messageArr.push({
                    message: `*${holidayToday.holiday}*`,
                    color: 'red'
                })
            }

            //handling how many trades there is on this day
            const finalAsDate = asDate;
            let tradesForThisDate = trades.filter(t => t.date.getTime() === finalAsDate.getTime());
            if (tradesForThisDate.length > 0) {
                messageArr.push({
                    message: `${tradesForThisDate.length} Trades`,
                    color: 'orange'
                });
            }

            let outline = null;
            let outlineColor = null;

            //checking if this is a furlough day
            for (let i = 0; i < myRequestedFurloughs.length; i++) {
                let startingDateMillis = myRequestedFurloughs[i].startingDate.getTime();
                let endingDateMillis = myRequestedFurloughs[i].endingDate.getTime();
                let currentDayMillis = asDate.getTime();

                if (startingDateMillis <= currentDayMillis && endingDateMillis >= currentDayMillis) {
                    outline = "solid"
                    outlineColor = "grey"
                }
            }


            days.push({
                date: asDate,
                messages: messageArr,
                outline,
                outlineColor
            });
        }

        //this is padding for the bottom
        for (let i = 0; i < ((42 - daysInMonth) - dayOffset); i++)
            days.push(this.placeholderObj((i + 1) + ""));

        console.log("days (c&p this)", days);
        return days;
    }

    render() {
        console.log("ffcalendarpage render", this.getPlaceholderOffset());

        //build the cellData
        // const { cellData } = this.state;
        var cellData = this.buildCellData();

        if (!cellData)
            return (<div><p>Loading...</p></div>)

        const { date } = this.getParentState();
        const monthAndYearString = date.toLocaleString('default', { month: 'long', year: 'numeric' });
        return (
            <>
                <FFCalendarTable
                    cellData={cellData}
                    clickHandler={(e, i) => this.clickHandler(e, i)}
                    seekHandler={(left) => this.seekHandler(left)}
                    navigationText={monthAndYearString}
                />
            </>
        )
    }

    //CELL PLACEHOLDER GENERATION
    placeholderObj(str) {
        return {
            placeholder: true,
            messages: str ? [{ message: str + "", color: "black" }] : []
        }
    };


}

export default FFProgrammedCalendar;