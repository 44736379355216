import { Component } from "react";
import { Button, Modal } from "react-bootstrap";


class FFCalendarConfirmTradeModal extends Component {
    state = {}
    getUser;
    getParentState;
    submitTradeToTradeBank;
    
    constructor(props) {
        super(props)
        this.getUser = props.getUser;
        this.getParentState = props.getParentState;
        this.submitTradeToTradeBank = props.submitTradeToTradeBank;
    }

    render() {
        const { showSubmitTrade, tradeType, modalData } = this.getParentState();

        if (!modalData) {
            return (
                <Modal show={showSubmitTrade} onHide={() => this.handleClose()} />
            );
        }

        const formattedDate = modalData.date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        return (
            <Modal show={showSubmitTrade} onHide={() => this.handleSubmitTradeClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Trading {tradeType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2 color={modalData.platoonWorking.color} >{modalData.platoonWorking.label.toUpperCase()} - {modalData.dailyDay}</h2>
                    <h2>{formattedDate}</h2>
                    <p>You are about to submit a {tradeType} trade for <span color={modalData.platoonWorking.color}>{modalData.platoonWorking.label.toUpperCase()} - {modalData.dailyDay}</span> on {formattedDate}. Do you agree to submit this?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.submitTradeToTradeBank()}>
                        Submit {tradeType}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default FFCalendarConfirmTradeModal;