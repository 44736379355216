import { Component } from "react";

import { Button, Form, Modal, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { postData } from "../../helpers/postData";
import { getHolidaysForMonth } from "../../helpers/Holidays";

// import { Style } from "./FFCalender.css";

const form = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%"
}

class FFCalender extends Component
{
    state = {
        platoonOrigin: new Date(2020, 11, 18), //when the platoon rotation officially starts (there is no end) HARDCODE
        emsOrigin: new Date(2020, 11, 17), //HARDCODE
        date: new Date(),
        show: false,
        modalData: null,
        rotationInformation: null,
        platoons: [],
        showSubmitTrade: false,
        tradeType: null,
        trades: [],
        myRequestedFurloughs: []
    };

    getUser = null;

    constructor(props)
    {
        super(props);
        this.getUser = this.props.getUser;
    }

    async componentDidMount()
    {
        let r1 = await postData('/getplatoons', {})
        let platoons = await r1.json();
        console.log("platoons", platoons);
        let r2 = await postData('/gettradesforthismonth', { date: this.state.date });
        let tradeObj = await r2.json();
        console.log("tradeObj", tradeObj);

        let newState = {
            platoons,
            trades: []
        };

        //trades
        if (tradeObj.trades)
        {
            newState.trades = tradeObj.trades;
            for (let i = 0; i < newState.trades.length; i++)
                newState.trades[i].date = new Date(newState.trades[i].date);
        }

        //furloughs
        let r3 = await postData('/currentlyrequestedfurloughs', {});
        let furloughJson = await r3.json();
        for (let i = 0; i < furloughJson.requestedFurloughs.length; i++)
        {
            furloughJson.requestedFurloughs[i].startingDate = new Date(furloughJson.requestedFurloughs[i].startingDate)
            furloughJson.requestedFurloughs[i].endingDate = new Date(furloughJson.requestedFurloughs[i].endingDate)
        }
        newState.myRequestedFurloughs = furloughJson.requestedFurloughs;

        this.setState(newState);
    }

    async showWorkDayOnModal(date)
    {
        if (!date)
            return;

        const { platoonOrigin, emsOrigin, platoons } = this.state;
        console.log("working day modal called!", date)

        let distanceBetweenPlatoonOrigin = this.distanceInDaysBetweenDates(platoonOrigin, date);
        let distanceBetweenEmsOrigin = this.distanceInDaysBetweenDates(emsOrigin, date);

        let dailyDay = this.getLetterCombination((Math.floor(distanceBetweenPlatoonOrigin / 3) % 5) + 1);
        let platoonLoop = platoons[distanceBetweenPlatoonOrigin % 3];

        console.log(dailyDay);
        console.log(platoonLoop);

        let r1 = await postData('/whosworkingtoday', { isodate: date.toISOString() });
        let whosWorkingToday = await r1.json();
        whosWorkingToday = whosWorkingToday.peopleWorkingToday;
        console.log("WHOS WORKING TODAY", whosWorkingToday);

        let r2 = await postData('/requesttradesforcalenderday', { date });
        let tradeObj = await r2.json();
        console.log("TRADES TODAY", tradeObj);
        for (let i = 0; i < tradeObj.trades.length; i++)
        {
            tradeObj.trades[i].date = new Date(tradeObj.trades[i].date);
            tradeObj.trades[i].created = new Date(tradeObj.trades[i].created);
        }


        const modalData = {
            date,
            dailyDay,
            platoonWorking: platoonLoop,
            whosWorkingToday,
            trades: tradeObj.trades
        }

        this.setState({ show: true, modalData });
    }

    handleClose()
    {
        this.setState({ show: false })
    }

    handleSubmitTradeClose()
    {
        this.setState({ showSubmitTrade: false })
    }

    handleCloseAndOpenSubmitTrade(tradeType)
    {
        this.setState({ show: false, showSubmitTrade: true, tradeType });
    }

    placeholderObj(str)
    {
        return {
            placeholder: true,
            messages: str ? [{ message: str + "", color: "black" }] : []
        }
    };

    getRawDateStr(date)
    {
        return date.toISOString().split("T")[0];
    }

    getLastDayOfMonth(date)
    {
        var clonedDate = new Date(date.getTime());
        clonedDate.setDate(1);
        clonedDate.setMonth(clonedDate.getMonth() + 1);
        clonedDate.setDate(clonedDate.getDate() - 1);
        return clonedDate.getDate();
    }

    distanceInDaysBetweenDates(d1, d2)
    {
        const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        return Math.round(((d2.getTime() - d1.getTime()) / oneDay));
    }

    getLetterCombination(num)
    {
        let result = "";
        while (num > 0)
        {
            num--;
            result = String.fromCharCode(num % 26 + 65) + result;
            num = Math.floor(num / 26);
        }
        return result;
    }

    async seekCurrentMonthOfCalender(forward)
    {
        const { date } = this.state;
        let d8 = new Date(date.getTime());
        d8.setMonth(d8.getMonth() + (forward ? 1 : -1));

        let r2 = await postData('/gettradesforthismonth', { date: d8 });
        let trades = await r2.json();
        console.log("trades", trades);

        let r3 = await postData('/currentlyrequestedfurloughs', {});
        let furloughJson = await r3.json();
        for (let i = 0; i < furloughJson.requestedFurloughs.length; i++)
        {
            furloughJson.requestedFurloughs[i].startingDate = new Date(furloughJson.requestedFurloughs[i].startingDate)
            furloughJson.requestedFurloughs[i].endingDate = new Date(furloughJson.requestedFurloughs[i].endingDate)
        }
        let furloughs = furloughJson.requestedFurloughs;

        console.log("AYYYOOOOO WHATS THE FURLOUGHS FAM", furloughs);


        let newState = {
            date: d8,
            trades: []
        };
        if (trades.trades)
        {
            newState.trades = trades.trades;
            for (let i = 0; i < newState.trades.length; i++)
                newState.trades[i].date = new Date(newState.trades[i].date);
        }


        this.setState(newState);
    }

    buildCalenderObjects()
    {
        // const { workingDays } = this.getUser();
        const { platoonOrigin, emsOrigin, platoons, trades, myRequestedFurloughs } = this.state;
        // console.log("ffcalender buildcalenderobjects workingdays", workingDays);
        // workingDays.forEach(wd => console.log("loop", wd.date.toISOString().split("T")[0]));

        var date = new Date(this.state.date.getTime());
        date.setDate(1); //setting it to first of the month
        date.setHours(0, 0, 0, 0); //setting it to midnight

        var daysInMonth = this.getLastDayOfMonth(date);

        var days = [];

        var holidays = getHolidaysForMonth(date.getMonth(), date.getFullYear());

        var shownCFDWelcomeMessage = false;

        //get trade bank trades for this month
        console.log("buildcalender: trades:", trades);

        //this is padding for dates where the first day of the month isn't sunday.
        var dayOffset = date.getDay();
        for (let i = 0; i < dayOffset; i++)
        {
            if (!shownCFDWelcomeMessage)
            {
                days.push(this.placeholderObj("ALL CFD members\nare welcome to join\nour credit union"));
                shownCFDWelcomeMessage = true;
            }
            else
            {
                let d8 = new Date(date.getTime());
                d8.setDate(1);
                d8.setDate(d8.getDate() - (dayOffset - i));
                days.push(this.placeholderObj(d8.getDate() + ""));
            }


        }

        //start making our days into objects
        for (let i = 0; i < daysInMonth; i++)
        {
            // const workingDay = workingDays.findIndex(wd => this.getRawDateStr(wd.date) === this.getRawDateStr(date)); //old
            // console.log("calender day construction: ", i, workingDay);

            var messageArr = [];

            var asDate = new Date(date.getTime());
            asDate.setDate(asDate.getDate() + i);

            let distanceBetweenPlatoonOrigin = this.distanceInDaysBetweenDates(platoonOrigin, asDate);
            let distanceBetweenEmsOrigin = this.distanceInDaysBetweenDates(emsOrigin, asDate);
            if (platoons.length > 0 && distanceBetweenPlatoonOrigin >= 0 && distanceBetweenEmsOrigin >= 0)
            {
                //platoon calculation
                let dailyDay = this.getLetterCombination((Math.floor(distanceBetweenPlatoonOrigin / 3) % 5) + 1);
                let platoonLoop = platoons[distanceBetweenPlatoonOrigin % 3];
                messageArr.push({
                    message: `${asDate.getDate()}-${dailyDay}`,
                    color: platoonLoop.color,
                });

                //ems calculation
                let emsLoop = Math.floor(distanceBetweenEmsOrigin % 4);
                messageArr.push({
                    message: `EMS-${emsLoop + 1}`,
                    color: 'black',
                });
            }
            else
            {
                messageArr.push({
                    message: `${asDate.getDate()}`,
                    color: 'black',
                });
            }


            //handling holidays for this date
            var holidayToday = holidays[i + 1];
            if (holidayToday)
            {
                messageArr.push({
                    message: `*${holidayToday.holiday}*`,
                    color: 'red'
                })
            }

            //handling how many trades there is on this day
            const finalAsDate = asDate;
            let tradesForThisDate = trades.filter(t => t.date.getTime() === finalAsDate.getTime());
            if (tradesForThisDate.length > 0)
            {
                messageArr.push({
                    message: `${tradesForThisDate.length} Trades`,
                    color: 'orange'
                });
            }

            let outline = null;
            let outlineColor = null;

            //checking if this is a furlough day
            for (let i = 0 ; i < myRequestedFurloughs.length ; i++)
            {
                let startingDateMillis = myRequestedFurloughs[i].startingDate.getTime();
                let endingDateMillis = myRequestedFurloughs[i].endingDate.getTime();
                let currentDayMillis = asDate.getTime();

                if (startingDateMillis <= currentDayMillis && endingDateMillis >= currentDayMillis)
                {
                    outline = "solid"
                    outlineColor = "grey"
                }
            }


            days.push({
                date: asDate,
                messages: messageArr,
                outline,
                outlineColor
            });
        }

        //this is padding for the bottom
        for (let i = 0; i < ((42 - daysInMonth) - dayOffset); i++)
            days.push(this.placeholderObj((i + 1) + ""));

        console.log("days (c&p this)", days);
        return days;
    }

    buildRowsForCalender()
    {
        const days = this.buildCalenderObjects();
        var rows = [];

        for (var r = 0; r < 6; r++)
        {
            var beginningIndex = r * 7
            var endingIndex = r * 7 + 7
            var sliced = days.slice(beginningIndex, endingIndex);

            rows.push((
                <tr key={r}>
                    {
                        sliced.map((e, i) => (
                            <td key={i} className={e.placeholder ? "ffPlaceholderCell" : null} style={{ width: "14.2857%", height: "17.5%", padding: "0", margin: "0", boxSizing: "border-box", outline: e.outline, outlineColor: e.outlineColor }}>
                                <div key={i} className={e.placeholder ? "ffPlaceholderCell" : null} style={{ textAlign: "center" }} onClick={() => this.showWorkDayOnModal(e.date)}>
                                    {
                                        e.messages.map(messageObject => (<p style={{ color: messageObject.color }} >{messageObject.message}</p>))
                                    }
                                </div>
                            </td>
                            
                        ))
                    }
                </tr>
            ))
        }
        return rows;
    }

    buildCalender()
    {
        var builtCalenderHeader = this.buildCalenderHeader();
        var builtRows = this.buildRowsForCalender();
        return (
            <>
                <div className="flexCentered flexY" style={{ width: "100%", height: "100%" }}>
                    {
                        builtCalenderHeader
                    }
                    <div style={{ width: "100%", height: "100%" }}>
                        <table id="ffCalender" style={{ width: "100%", height: "100%" }}>
                            <thead>
                                <tr className="abbreviated">
                                    <th>Sun</th>
                                    <th>Mon</th>
                                    <th>Tue</th>
                                    <th>Wed</th>
                                    <th>Thu</th>
                                    <th>Fri</th>
                                    <th>Sat</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    builtRows
                                }
                            </tbody>
                        </table>
                    </div>

                </div>
            </>

        );
    }

    buildCalenderHeader()
    {
        const { date, platoons } = this.state;
        const dateString = date.toLocaleString('default', { month: 'long', year: 'numeric' });

        const { platoon } = this.getUser();

        const platoonObjUserBelongsTo = platoons.find(e => e.label === platoon);

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: "100%", paddingTop: '10px' }}>
                    <h1>Chicago Fire Department</h1>

                    {/* your platoon is... */}
                    {
                        platoonObjUserBelongsTo ? (
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <p style={{ paddingRight: "5px" }}>YOUR PLATOON IS</p>
                                <p color={platoonObjUserBelongsTo.color}>{platoon.toUpperCase()}</p>
                            </div>
                        ) : null
                    }

                    {/* seeking the months */}
                    <div style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', gap: '10px', paddingBottom: '10px' }}>
                        <input type="button" value="<" onClick={() => this.seekCurrentMonthOfCalender(false)} />
                        <p>{dateString}</p>
                        <input type="button" value=">" onClick={() => this.seekCurrentMonthOfCalender(true)} />
                    </div>
                </div>
            </>
        )
    }

    async cancelOrOfferAgainstTrade(tradeIndex)
    {
        const { modalData } = this.state;
        const tradeWanted = modalData.trades[tradeIndex];
        // const user = this.getUser();
        console.log("TradeWanted", tradeWanted);

        const { date, type, creator } = tradeWanted;

        let r1 = await postData('/interactwithtrade', { date, type, creator });
        let json = await r1.json();
        console.log("Interacting with trade message", json);

        this.showWorkDayOnModal(date);
    }

    async acceptOfferOnTrade(tradeIndex, personOffering)
    {
        console.log("ACCEPT OFFER ON TRADE", tradeIndex, personOffering);

        const { modalData } = this.state;
        const tradeWanted = modalData.trades[tradeIndex];

        const { date, type, creator } = tradeWanted;

        let r1 = await postData('/acceptofferontrade', { date, type, personOffering });
        let json = await r1.json();
        console.log("ACCEPTOFFERONTRADE RESPONSE", json);
    }

    //people working today modal
    renderModal()
    {
        const { show, modalData } = this.state;
        const { platoonOrigin, emsOrigin, platoons } = this.state;
        const user = this.getUser();

        if (!modalData)
        {
            return (
                <Modal show={show} onHide={() => this.handleClose()} />
            );
        }

        const formattedDate = modalData.date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        // let distanceBetweenEmsOrigin = this.distanceInDaysBetweenDates(emsOrigin, modalData.date);

        console.log("MODAL DATA!!!!!!", modalData);

        return (
            <Modal show={show} onHide={() => this.handleClose()}>

                <Modal.Header closeButton>
                    <Modal.Title>{modalData.platoonWorking.label.toUpperCase()} - {modalData.dailyDay}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group style={form}>
                            <h3>{formattedDate}</h3>
                            <h4 color={modalData.platoonWorking.color}>People working today</h4>

                            <ul>
                                {
                                    modalData.whosWorkingToday.map(e => (
                                        <li>{e.username}</li>
                                    ))
                                }
                            </ul>
                        </Form.Group>

                        {
                            modalData.trades.length > 0 ? (
                                <Form.Group style={form}>
                                    <h3>Posted Trades</h3>

                                    {
                                        modalData.trades.map((t, i) => ( //loop through all the trades for this day
                                            <div style={{ display: 'flex', width: '80%', textAlign: 'center', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px', flexDirection: 'column' }}>
                                                <p>{t.creator}</p>
                                                <p>Type: {t.type}</p>
                                                <p>Posted on {t.created.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '/')}</p>

                                                { //show users how many offers there are on this trade
                                                    t.offers.length > 0 ? (
                                                        <p>{t.offers.length} Offers</p>
                                                    ) : null
                                                }

                                                { //render each offer for the user who created the trade to pick each offer
                                                    (t.creator === user.username) ? (
                                                        <div>
                                                            {
                                                                t.offers.map((o) => (
                                                                    <div>
                                                                        {o.creator}
                                                                        <Button variant="primary" onClick={() => this.acceptOfferOnTrade(i, o.creator)}>Accept Offer</Button>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ) : null
                                                }

                                                <Button variant="primary" onClick={() => this.cancelOrOfferAgainstTrade(i)}>
                                                    {
                                                        t.creator === user.username ?
                                                            "Cancel Trade" : "Make an Offer"
                                                    }
                                                </Button>
                                            </div>

                                        ))
                                    }

                                </Form.Group>
                            ) : null
                        }


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {

                        !((modalData.dailyDay === user.dailyOff) || (modalData.platoonWorking.label !== user.platoon) || (modalData.trades.findIndex(t => t.creator === user.username) !== -1)) ?
                            (
                                <>
                                    <Button variant="primary" onClick={() => this.handleCloseAndOpenSubmitTrade("Daily Day")}>
                                        Trade as Daily day
                                    </Button>
                                    <Button variant="primary" onClick={() => this.handleCloseAndOpenSubmitTrade("Platoon Day")}>
                                        Trade as Platoon day
                                    </Button>
                                </>
                            ) : null
                        //if this calenders daily day === his off daily
                        //dont render the trade buttons
                    }
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                    </Button>

                </Modal.Footer>

            </Modal>
        )
    }

    async submitTradeToTradeBank()
    {
        const { tradeType, modalData } = this.state;
        const { date } = modalData;

        let r1 = await postData('/submittrade', {
            tradeType,
            date
        });
        let json = await r1.json();
        console.log("response from server", json);
        this.setState({ showSubmitTrade: false });
        this.showWorkDayOnModal(date);
    }

    renderSubmitTradeModal()
    {
        const { showSubmitTrade, tradeType, modalData } = this.state;

        if (!modalData)
        {
            return (
                <Modal show={showSubmitTrade} onHide={() => this.handleClose()} />
            );
        }

        const formattedDate = modalData.date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });

        return (
            <Modal show={showSubmitTrade} onHide={() => this.handleSubmitTradeClose()}>
                <Modal.Header closeButton>
                    <Modal.Title>Trading {tradeType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h2 color={modalData.platoonWorking.color} >{modalData.platoonWorking.label.toUpperCase()} - {modalData.dailyDay}</h2>
                    <h2>{formattedDate}</h2>
                    <p>You are about to submit a {tradeType} trade for <span color={modalData.platoonWorking.color}>{modalData.platoonWorking.label.toUpperCase()} - {modalData.dailyDay}</span> on {formattedDate}. Do you agree to submit this?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => this.submitTradeToTradeBank()}>
                        Submit {tradeType}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render()
    {
        var user = this.getUser();
        if (!user)
            return (<div><p>You have to be logged in to access the calender.</p></div>)

        return (
            <div style={{ width: "100%", height: "73vh" }} >
                {
                    this.buildCalender()
                }

                {
                    this.renderModal()
                }

                {
                    this.renderSubmitTradeModal()
                }
            </div>
        );
    }
}

export default FFCalender;