import React, { Component } from "react";
import LoginForm from "./components/LoginForm";
import SignatureCanvas from 'react-signature-canvas'
import { Button } from "react-bootstrap";
import { postFormData } from "../../helpers/postData";

class UserDash extends Component
{

    state = {

    }

    setUser = null;
    getUser = null;
    sigCanvas = React.createRef();

    constructor(props)
    {
        super(props);
        console.log("userdash", props)
        this.setUser = this.props.setUser;
        this.getUser = this.props.getUser;
        console.log("what is getuser?", this.getUser)
    }

    componentDidMount()
    {

    }

    componentDidUpdate()
    {

    }

    downloadSignature()
    {
        console.log("downloading sig");
        let canvas = this.sigCanvas.getTrimmedCanvas();
        const dataUrl = canvas.toDataURL('image/png'); // Convert to data URL

        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = 'signature.png';
        a.click();
    }

    async updateSignatureOnBackend()
    {
        console.log("updating sig");
        let canvas = this.sigCanvas.getTrimmedCanvas();
        const dataUrl = canvas.toDataURL('image/png'); // Convert to data URL

        // Convert data URL to Blob
        const blob = await (await fetch(dataUrl)).blob();

        // Create FormData object and append the image blob to it
        const formData = new FormData();
        formData.append('image', blob, 'signature.png');

        let response = await postFormData('/updatesignature', formData);
        let text = await response.text();


        // let formData = new FormData();
        // formData.append("image", dataUrl);

        // let response = await postFormData('/updatesignature', formData);
        // console.log(response);

        // let text = await response.text();

        console.log("after formdata", text);
    }

    base64ToArrayBuffer(base64)
    {
        const binaryString = window.atob(base64);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);

        for (let i = 0; i < length; i++)
        {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return bytes.buffer;
    }

    render()
    {
        const user = this.getUser();

        console.log("userdash: what is my user?", user)

        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', justifyContent: 'center', alignItems: 'center' }}>
                <p>userdash page</p>
                {
                    user ? (
                        <div>
                            <p>you are logged in!</p>

                            <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                                <p>Signature container</p>
                                <div style={{ outline: 'solid' }}>
                                    <SignatureCanvas
                                        ref={(ref) => { this.sigCanvas = ref }}
                                        penColor='black'
                                        canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                    />
                                </div>
                                <Button onClick={() => this.sigCanvas.clear()}>Clear</Button>
                                <Button onClick={() => this.downloadSignature()}>Download As Image</Button>
                                <Button onClick={() => this.updateSignatureOnBackend()}>Update Signature on backend</Button>

                            </div>

                        </div>
                    ) : (
                        <LoginForm setUser={(user) => this.setUser(user)} />
                    )
                }

            </div>
        )
    }

}

export default UserDash;