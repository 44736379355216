import { Component } from "react";

const PLATOONS = 3;
const DAILY_DAYS = 5;

class TestPage extends Component
{
    state = {}

    distanceInDaysBetweenDates(d1, d2)
    {
        const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
        return Math.round(Math.abs((d1.getTime() - d2.getTime()) / oneDay));
    }

    getLetterCombination(num)
    {
        let result = "";
        while (num > 0)
        {
            num--;
            result = String.fromCharCode(num % 26 + 65) + result;
            num = Math.floor(num / 26);
        }
        return result;
    }

    getPlatoonColor(platoonId)
    {
        switch (platoonId)
        {
            case 0:
                return 'red';
            case 1:
                return 'green';
            case 2:
                return 'blue';
            default:
                return 'black';
        }
    }

    renderItems()
    {
        var list = [];
        const originDate = new Date();
        const dynamicDate = new Date();
        for (let i = 0; i < (365 * 1); i++)
        {
            let distance = this.distanceInDaysBetweenDates(originDate, dynamicDate);

            let dailyDay = Math.floor(distance / PLATOONS) % DAILY_DAYS;
            let platoonLoop = distance % PLATOONS;

            list.push((<div style={{ display: 'flex', flexDirection: 'rows' }}>
                <p style={{ color: this.getPlatoonColor(platoonLoop) }}> {dynamicDate.toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "2-digit" }).replace(/\//g, ":")} {this.getLetterCombination(dailyDay + 1)} </p>
            </div>));

            dynamicDate.setDate(dynamicDate.getDate() + 1);
        }
        return list;
    }

    render()
    {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {this.renderItems()}
            </div>
        );
    }
}

export default TestPage;