import { Component } from "react";
import { postData } from "../../helpers/postData";
import { Button, Form, Modal, ListGroup } from "react-bootstrap";
import ComboBox from "../components/ComboBox";

class ModifyUsers extends Component
{
    state = {
        platoonRots: [],
        usernames: [],
        selectedUserObject: null,
        roles: [],

        //platoon rotation
        showPlatoonRotModal: false,
        selectedRotationsMatrix: [],

        //platoon user is apart of
        cachedPlatoons: [],
        selectedPlatoonMatrix: null,

        //roles
        showRolesModal: false,
        selectedRolesMatrix: []

    }

    async componentDidMount()
    {
        let r1 = await postData('/getusers', {});
        let usernames = await r1.json();

        let r2 = await postData('/getplatoonrotations', {});
        let platoonRots = await r2.json();
        console.log("platoonRots", platoonRots);

        let r3 = await postData('/getplatoons?labelsOnly=true');
        let cachedPlatoons = await r3.json();
        cachedPlatoons = cachedPlatoons.map(e => e.label);
        console.log("cachedPlatoons", cachedPlatoons);

        let r4 = await postData('/getroles', {});
        let roles = await r4.json();
        console.log("cached roles", roles);

        this.setState({ usernames, platoonRots, cachedPlatoons, roles });
    }

    async selectUser(username)
    {
        let r1 = await postData('/requestuserinformation', { username })
        let selectedUserObject = await r1.json();
        this.setState({ selectedUserObject });
    }

    async handlePlatoonRotModalOpen(e)
    {
        e.preventDefault();
        const { selectedUserObject, platoonRots } = this.state;
        let mapped = platoonRots.map(e => ({ label: e.label, active: selectedUserObject.platoonRotations.findIndex(r => r === e.label) !== -1 }));
        this.setState({ showPlatoonRotModal: true, selectedRotationsMatrix: mapped });
    }

    async handlePlatoonRotModalClose()
    {
        this.setState({ showPlatoonRotModal: false });
    }

    async handleRolesModalOpen(e)
    {
        e.preventDefault();
        const { selectedUserObject, platoonRots } = this.state;
        let mapped = platoonRots.map(e => ({ label: e.label, active: selectedUserObject.platoonRotations.findIndex(r => r === e.label) !== -1 }));
        this.setState({ showRolesModal: true, selectedRotationsMatrix: mapped });
    }

    async handleRolesModalClose()
    {
        this.setState({ showRolesModal: false });
    }

    async saveChangesModal()
    {
        const { selectedUserObject, selectedRotationsMatrix } = this.state;
        selectedUserObject.platoonRotations = selectedRotationsMatrix.filter(e => e.active).map(e => e.label);
        this.setState({ selectedUserObject, showPlatoonRotModal: false, selectedRotationsMatrix: [] });
    }

    async togglePlatoonRotationInMatrix(e, index)
    {
        e.preventDefault();
        const { selectedRotationsMatrix } = this.state;
        selectedRotationsMatrix[index].active = !selectedRotationsMatrix[index].active
        this.setState({ selectedRotationsMatrix });
    }

    modifiedUserPlatoon(newPlatoon)
    {
        const { selectedUserObject } = this.state;
        if (selectedUserObject === null || selectedUserObject === undefined)
            return;

        selectedUserObject.platoon = newPlatoon;

        this.setState({ selectedUserObject, selectedPlatoonMatrix: newPlatoon });
    }

    async updateUserOnServer()
    {
        const { selectedUserObject } = this.state;
        if (selectedUserObject === null || selectedUserObject === undefined)
            return;

        await postData('/updateuser', { username: selectedUserObject.username, rotations: selectedUserObject.platoonRotations, platoon: selectedUserObject.platoon });
        console.log("sent update user request");
    }

    render()
    {
        const { usernames, selectedUserObject, showPlatoonRotModal, platoonRots, selectedRotationsMatrix, cachedPlatoons, selectedPlatoonMatrix, showRolesModal, selectedRolesMatrix } = this.state;

        return (
            <div style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "84vh" }}>
                modifyusers page

                <div style={{ display: "flex", flexDirection: "row", padding: "5%", width: "100%", height: "100%" }}>
                    <div style={{ width: "25%", paddingRight: "5%", textAlign: "center" }}>
                        <label className="form-label">Users</label>
                        <ListGroup>
                            {
                                usernames.map(e => (
                                    <ListGroup.Item action onClick={() => this.selectUser(e.username)} >{e.username}</ListGroup.Item>
                                ))
                            }
                        </ListGroup>
                    </div>

                    <div style={{ width: "100%", textAlign: "center" }}>
                        {
                            selectedUserObject ? (
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Now Editing User {selectedUserObject.username}</Form.Label>
                                    </Form.Group>
                                    <Form.Group style={{ paddingTop: "25px" }}>
                                        <Form.Label>Platoon Rotations Apart Of</Form.Label>
                                        <ListGroup>
                                            {
                                                selectedUserObject.platoonRotations.length > 0 ?
                                                    selectedUserObject.platoonRotations.map(e => (<ListGroup.Item>{e}</ListGroup.Item>)) :
                                                    <ListGroup.Item>Empty</ListGroup.Item>
                                            }
                                            <ListGroup.Item action onClick={(e) => this.handlePlatoonRotModalOpen(e)}>Modify Platoon Rotations</ListGroup.Item>
                                        </ListGroup>
                                    </Form.Group>

                                    <Form.Group style={{ paddingTop: "25px" }}>
                                        <ListGroup>
                                            <Form.Label>Platoon Apart Of</Form.Label>
                                            <ComboBox selectedOption={selectedUserObject.platoon} options={cachedPlatoons} onSelect={(option) => this.modifiedUserPlatoon(option)} />
                                        </ListGroup>
                                    </Form.Group>

                                    <Form.Group style={{ paddingTop: "25px" }}>
                                        <Form.Label>Roles</Form.Label>
                                        <ListGroup>
                                            {
                                                selectedUserObject.roles.length > 0 ?
                                                    selectedUserObject.roles.map(e => (<ListGroup.Item>{e}</ListGroup.Item>)) :
                                                    <ListGroup.Item>Empty</ListGroup.Item>
                                            }
                                            <ListGroup.Item action onClick={(e) => this.handlePlatoonRotModalOpen(e)}>Modify Roles</ListGroup.Item>
                                        </ListGroup>
                                    </Form.Group>

                                    <Form.Group style={{ paddingTop: "35px" }}>
                                        <input type="button" value="Update User Data" onClick={() => this.updateUserOnServer()} />
                                    </Form.Group>
                                </Form>
                            ) : (
                                <p>Please Select a User</p>
                            )
                        }
                    </div>
                </div>

                {/* platoon rot modal */}
                <Modal show={showPlatoonRotModal} onHide={() => this.handlePlatoonRotModalClose()}>
                    {
                        selectedUserObject ? (<>
                            <Modal.Header closeButton>
                                <Modal.Title>Editing {selectedUserObject.username}'s Platoon Rotations</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "10px" }}>
                                    <Form.Group>
                                        <Form.Label>Label</Form.Label>
                                        <ListGroup>
                                            {
                                                selectedRotationsMatrix.map((e, i) => (<ListGroup.Item active={e.active} action onClick={(ev) => this.togglePlatoonRotationInMatrix(ev, i)}>{e.label}</ListGroup.Item>))
                                            }
                                        </ListGroup>

                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.handlePlatoonRotModalClose()}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => this.saveChangesModal()}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </>) : null
                    }
                </Modal>

                {/* roles modal */}
                <Modal show={showRolesModal} onHide={() => this.handlePlatoonRotModalClose()}>
                    {
                        selectedUserObject ? (<>
                            <Modal.Header closeButton>
                                <Modal.Title>Editing {selectedUserObject.username}'s Platoon Rotations</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "10px" }}>
                                    <Form.Group>
                                        <Form.Label>Label</Form.Label>
                                        <ListGroup>
                                            {
                                                selectedRotationsMatrix.map((e, i) => (<ListGroup.Item active={e.active} action onClick={(ev) => this.togglePlatoonRotationInMatrix(ev, i)}>{e.label}</ListGroup.Item>))
                                            }
                                        </ListGroup>

                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.handlePlatoonRotModalClose()}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => this.saveChangesModal()}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </>) : null
                    }
                </Modal>

            </div>
        )
    }
}

export default ModifyUsers