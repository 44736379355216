import { Component } from "react";


class FFCalendarCell extends Component {
    state = {
        cellInfo: null
    }

    onClick() {
        if (this.props.onClick)
            this.props.onClick()
    }

    componentDidMount() {
        const { cellInfo } = this.props;
        this.setState({ cellInfo })
    }

    componentDidUpdate(prevProps) {
        const { cellInfo } = this.props;
        if (cellInfo !== prevProps.cellInfo)
            this.setState({ cellInfo })
    }

    formatStyle(messageObject) {
        return (({ message, ...rest }) => rest)(messageObject);
    }

    render() {
        const { cellInfo } = this.state;
        if (!cellInfo)
            return (<></>)

        return (
            <div onClick={() => this.onClick ? this.onClick() : null}>
                {
                    cellInfo.messages.map((messageObject, i) => (<p key={i} style={this.formatStyle(messageObject)} >{messageObject.message}</p>))
                }
            </div>
        )
    }
}

export default FFCalendarCell;