import { Component } from "react";
import { Button } from "react-bootstrap";
import { postData } from "../../helpers/postData";


class AdminAuditPage extends Component
{

    state = {
        auditResults: []
    };


    async requestAudit()
    {
        let r1 = await postData('/requestaudits', {});
        let json = await r1.json();
        const { auditResults } = json;
        console.log("auditResults", auditResults);
        this.setState({ auditResults });
    }





    render()
    {
        const { auditResults } = this.state;
        console.log("RENDER AUDIT RESULTS", auditResults);

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                admin audit page


                <Button onClick={() => this.requestAudit()}>
                    Testing button
                </Button>

                {
                    auditResults.map((e, i) => (
                        <div>
                            <ul>
                                {
                                    Object.keys(e).map(key => (<li>{key}: {e[key]}</li>))
                                }
                            </ul>
                        </div>
                    ))
                }


            </div>
        )
    }

}

export default AdminAuditPage;