import { Component } from "react";
import { postData, postFormData } from "../../helpers/postData";
import { Button } from "react-bootstrap";
import { generateFurloughs } from "./FurloughUtils"

const dateformat = { month: 'long', day: 'numeric', year: 'numeric' };

const firstPlatoonOrigin = new Date(2021, 0, 2);
const secondPlatoonOrigin = new Date(2021, 0, 3);
const thirdPlatoonOrigin = new Date(2021, 0, 1);

class FurloughsPage extends Component
{

    state = {
        platoons: null,
        requestedFurloughs: [],
        thisYear: new Date()
    }

    async componentDidMount()
    {
        let r1 = await postData('/getplatoons', {});
        let platoons = await r1.json();
        console.log(platoons);


        let r2 = await postData('/currentlyrequestedfurloughs', {});
        let response = await r2.json();
        let requestedFurloughs = response.requestedFurloughs;
        for (let i = 0; i < requestedFurloughs.length; i++)
        {
            let rf = requestedFurloughs[i];
            rf.startingDate = new Date(rf.startingDate);
            rf.endingDate = new Date(rf.endingDate);
        }

        console.log("requested furloughs", requestedFurloughs);

        this.setState({ platoons, requestedFurloughs });
    }

    async requestFurlough(furloughDates, platoon)
    {
        console.log("requesting furlough", furloughDates, platoon);
        let r1 = await postData('/requestfurlough', {
            ...furloughDates,
            platoon
        });
        let json = await r1.json();
        console.log("json", json);
        let requestedFurloughs = this.state.requestedFurloughs;
        console.log("1", requestedFurloughs);
        console.log("1a", furloughDates);
        if (json.status === "added")
            requestedFurloughs.push({ ...furloughDates, platoon });
        else if (json.status === "removed")
            requestedFurloughs = requestedFurloughs.filter(f => !(f.startingDate.getTime() === furloughDates.startingDate.getTime() && f.endingDate.getTime() === furloughDates.endingDate.getTime()));
        console.log("2", requestedFurloughs);
        this.setState({ requestedFurloughs });
    }


    isFurloughRequested(furloughDates, platoon)
    {
        const { startingDate, endingDate } = furloughDates;
        const { requestedFurloughs } = this.state;
        for (let i = 0; i < requestedFurloughs.length; i++)
        {
            let rf = requestedFurloughs[i];
            if (startingDate.getTime() === rf.startingDate.getTime() && endingDate.getTime() === rf.endingDate.getTime() && rf.platoon === platoon)
                return true;
        }
        return false;
    }


    getFurloughsForYear(originDate, dateWithWantedYear)
    {
        let targetFirstDate = originDate;

        if (targetFirstDate.getFullYear() === dateWithWantedYear.getFullYear())
            return generateFurloughs(targetFirstDate);

        var yearsIterated = 0;
        while (targetFirstDate.getFullYear() !== dateWithWantedYear.getFullYear())
        {
            let generated = generateFurloughs(targetFirstDate)
            targetFirstDate = generated[generated.length - 1].endingDate;
            yearsIterated++;
        }
        targetFirstDate.setDate(targetFirstDate.getDate() - yearsIterated);
        return generateFurloughs(targetFirstDate);
    }

    seekFurloughYear(forward)
    {
        const { thisYear } = this.state;
        let newDate = new Date(thisYear.getTime());
        newDate.setFullYear(newDate.getFullYear() + (forward ? 1 : -1));
        this.setState({ thisYear: newDate });
    }


    render()
    {
        const { platoons, thisYear } = this.state

        let firstPlatoonGenerated = this.getFurloughsForYear(firstPlatoonOrigin, thisYear);
        let secondPlatoonGenerated = this.getFurloughsForYear(secondPlatoonOrigin, thisYear);
        let thirdPlatoonGenerated = this.getFurloughsForYear(thirdPlatoonOrigin, thisYear);

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                {/* Year Seeker */}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <input type="button" value="<" onClick={() => this.seekFurloughYear(false)} />
                    <h1>{thisYear.toLocaleDateString(undefined, { year: 'numeric' })}</h1>
                    <input type="button" value=">" onClick={() => this.seekFurloughYear(true)} />
                </div>


                <div style={{ display: 'flex', gap: '25px', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h4>1ST PLATOON</h4>
                        {
                            firstPlatoonGenerated.map((e, i) => (
                                <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: "15px" }}>
                                    <p style={{ color: platoons ? platoons[0].color : null }}>{i + 1}) {e.startingDate.toLocaleDateString('en-US', dateformat)} to {e.endingDate.toLocaleDateString('en-US', dateformat)}</p>
                                    <Button variant={this.isFurloughRequested(e, "PL1") ? "danger" : "primary"}  onClick={() => this.requestFurlough(e, "PL1")}>{this.isFurloughRequested(e, "PL1") ? "Remove" : "Request"} Furlough {i + 1}</Button>
                                </div>
                            ))
                        }
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h4>2ND PLATOON</h4>
                        {
                            secondPlatoonGenerated.map((e, i) => (
                                <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: "15px" }}>
                                    <p style={{ color: platoons ? platoons[1].color : null }}>{i + 1}) {e.startingDate.toLocaleDateString('en-US', dateformat)} to {e.endingDate.toLocaleDateString('en-US', dateformat)}</p>
                                    <Button variant={this.isFurloughRequested(e, "PL2") ? "danger" : "primary"}  onClick={() => this.requestFurlough(e, "PL2")}>{this.isFurloughRequested(e, "PL2") ? "Remove" : "Request"} Furlough {i + 1}</Button>                                    
                                </div>
                            ))
                        }
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h4>3RD PLATOON</h4>
                        {
                            thirdPlatoonGenerated.map((e, i) => (
                                <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: "15px" }}>
                                    <p style={{ color: platoons ? platoons[2].color : null }}>{i + 1}) {e.startingDate.toLocaleDateString('en-US', dateformat)} to {e.endingDate.toLocaleDateString('en-US', dateformat)}</p>
                                    <Button variant={this.isFurloughRequested(e, "PL3") ? "danger" : "primary"}  onClick={() => this.requestFurlough(e, "PL3")}>{this.isFurloughRequested(e, "PL3") ? "Remove" : "Request"} Furlough {i + 1}</Button>                                    
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div>
        )
    }

}

export default FurloughsPage;