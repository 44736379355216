import { Component } from "react";

class CalendarBuilderPage extends Component
{
    state = {};

    constructor(props)
    {
        super(props);
    }

    render()
    {
        return (
            <div>
                Your calendars:
                <ul>
                    <li>test</li>
                    <li>test</li>
                    <li>test</li>
                    <li>test</li>
                </ul>

            </div>
        )
    }
}

export default CalendarBuilderPage;