import { Button, Card } from "react-bootstrap";
import { postData } from "../../helpers/postData";

const { Component } = require("react");


class ConfirmedTradesPage extends Component
{
    state = {
        swapdays: []
    }

    formatDate(date)
    {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
    }

    async componentDidMount()
    {
        let rq = await postData('/getswapdaylist', {});
        let swapdays = await rq.json();
        //lets sort them
        for (let i = 0; i < swapdays.length; i++)
        {
            swapdays[i].date = new Date(swapdays[i].date);
            swapdays[i].created = new Date(swapdays[i].created);
        }
        swapdays.sort((a, b) => b.created - a.created)
        console.log("swapdays", swapdays);
        this.setState({ swapdays });
    }

    async createFormForTrade(tradeid)
    {
        console.log("creating form for tradeid", tradeid);

        let response = await postData('/generateformfortradeid', { tradeid });
        let arraybuffer = await response.arrayBuffer();

        const blob = new Blob([arraybuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const downloadUrl = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `trade_${tradeid}.docx`;
        link.click();
    }

    render()
    {
        const { swapdays } = this.state;
        const { formatDate } = this;

        return (
            <div>
                <p>confirmed trades page</p>
                {
                    swapdays.map(e => (
                        <Card className="data-card">
                            <Card.Body>
                                <div className="data-row" key={e.tradeReference}>
                                    <p>trade id: {e.tradeReference}</p>
                                    <p>trade behalf: {e.behalf}</p>
                                    <p>trade replacement: {e.replacement}</p>
                                    <p>trade date wanted: {formatDate(e.date)}</p>
                                    <p>trade date created: {formatDate(e.created)}</p>
                                    <Button onClick={() => this.createFormForTrade(e.tradeReference)}>Get as digital form</Button>
                                </div>
                            </Card.Body>
                        </Card>

                    ))
                }
            </div>
        )
    }
}

export default ConfirmedTradesPage;