/*
Federal Holidays

    **JANUARY**
    New Year’s Day January 1
    Martin Luther King’s Birthday 3rd Monday in January

    **FEBRUARY**
    Washington’s Birthday 3rd Monday in February

    **MAY**
    Memorial Day last Monday in May

    **JUNE**
    Juneteenth National Independence Day June 19

    **JULY**
    Independence Day July 4

    **SEPTEMBER**
    Labor Day 1st Monday in September

    **OCTOBER**
    Columbus Day 2nd Monday in October

    **NOVEMBER**
    Veterans’ Day November 11
    Thanksgiving Day 4th Thursday in November

    **DECEMBER**
    Christmas Day December 25
*/

const getJanuaryHolidays = (year) =>
{
    let date = new Date(year, 0, 1);
    let list = {};

    //new years day
    list["1"] = { holiday: `New Year's Day`, paid: true };

    //calculating mlk day
    while (true)
    {
        if (date.getDay() === 1)
            break;
        date.setDate(date.getDate() + 1);
    }
    date.setDate(date.getDate() + 14);
    list[date.getDate() + ""] = { holiday: "MLK Day", paid: true };

    return list;
}

const getFebruaryHolidays = (year) =>
{
    let date = new Date(year, 1, 1);
    let list = {};

    //in chicago they recognize lincolns birthday (not a federal holiday)
    list["12"] = { holiday: "Lincoln's Birthday", paid: true }

    //calculating washinghtons birthday (same as mlk day)
    while (true)
    {
        if (date.getDay() === 1)
            break;
        date.setDate(date.getDate() + 1);
    }
    date.setDate(date.getDate() + 14);
    list[date.getDate() + ""] = { holiday: "Presidents Day", paid: true }

    return list;
}

const getMarchHolidays = (year) =>
{
    let date = new Date(year, 2, 1);
    let list = {};

    //calculating pulaski day (not a federal holiday)
    while (true)
    {
        if (date.getDay() === 1)
            break;
        date.setDate(date.getDate() + 1);
    }
    list[date.getDate() + ""] = { holiday: "Pulaski Day", paid: true }
    return list;
}

const getMayHolidays = (year) =>
{
    let date = new Date(year, 4, 1);
    let list = {};

    //search for memorial day
    //make date go one month
    date.setMonth(date.getMonth() + 1);
    //subtract one day
    date.setDate(date.getDate() - 1);
    //now we have the date as the last day of may

    while (true)
    {
        if (date.getDay() === 1)
            break;
        date.setDate(date.getDate() - 1);
    }
    list[date.getDate() + ""] = { holiday: "Memorial Day", paid: true }

    return list;
}

const getJuneHolidays = () =>
{
    let list = {};
    list["14"] = { holiday: "Flag Day", paid: true }
    list["19"] = { holiday: "Juneteenth", paid: true }
    return list;
}

const getJulyHolidays = () =>
{
    let list = {};
    list["4"] = { holiday: "Independence Day", paid: true }
    return list;
}

const getSeptemberHolidays = (year) =>
{
    let date = new Date(year, 8, 1);
    let list = {};

    //calculate labor day
    while (true)
    {
        if (date.getDay() === 1)
            break;

        date.setDate(date.getDate() + 1);
    }
    list[date.getDate() + ""] = { holiday: "Labor Day", paid: true }
    return list;
}

const getOctoberHolidays = (year) =>
{
    let date = new Date(year, 9, 1);
    let list = {};

    //calculate columbus day
    while (true)
    {
        if (date.getDay() === 1)
            break;
        date.setDate(date.getDate() + 1);
    }
    date.setDate(date.getDate() + 7);
    list[date.getDate() + ""] = { holiday: "Columbus Day", paid: true }
    return list;
}

const getNovemberHolidays = (year) =>
{
    let date = new Date(year, 10, 1);
    let list = {};

    list["11"] = { holiday: "Veteran's Day", paid: true };

    //calculate thanksgiving
    while (true)
    {
        if (date.getDay() === 4) //thursday
            break;

        date.setDate(date.getDate() + 1);
    }
    date.setDate(date.getDate() + (7 * 3));

    list[date.getDate() + ""] = { holiday: "Thanksgiving Day", paid: true };

    return list;
}

const getDecemberHolidays = () =>
{
    let list = {};
    list["25"] = { holiday: "Christmas", paid: true };
    return list;
}

export const getHolidaysForMonth = (monthIndex, year) =>
{

    switch (monthIndex)
    {
        case 0:
            return getJanuaryHolidays(year);
        case 1:
            return getFebruaryHolidays(year);
        case 2:
            return getMarchHolidays(year);
        case 4:
            return getMayHolidays(year);
        case 5:
            return getJuneHolidays();
        case 6:
            return getJulyHolidays();
        case 8:
            return getSeptemberHolidays(year);
        case 9:
            return getOctoberHolidays(year);
        case 10:
            return getNovemberHolidays(year);
        case 11:
            return getDecemberHolidays();
        default:
            return {};
    }
}