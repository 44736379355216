import { Component } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const form = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%"
}

class FFCalendarDayModal extends Component {
    state = {}
    getParentState;
    getUser;
    handleClose;
    handleCloseAndOpenSubmitTrade;
    handleSubmitTradeClose;
    cancelOrOfferAgainstTrade;

    constructor(props) {
        super(props)
        this.getParentState = props.getParentState;
        this.getUser = props.getUser;
        this.handleClose = props.handleClose;
        this.handleCloseAndOpenSubmitTrade = props.handleCloseAndOpenSubmitTrade;
        this.handleSubmitTradeClose = props.handleSubmitTradeClose;
        this.cancelOrOfferAgainstTrade = props.cancelOrOfferAgainstTrade;
    }

    render() {
        const parentState = this.getParentState();
        const { show, modalData } = parentState;
        const { platoonOrigin, emsOrigin, platoons } = parentState;
        const user = this.getUser();

        console.log("FFCalendarDayModal", modalData);

        if (!modalData) {
            return (
                <Modal show={show} onHide={() => this.handleClose()} />
            );
        }

        const formattedDate = modalData.date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        // let distanceBetweenEmsOrigin = this.distanceInDaysBetweenDates(emsOrigin, modalData.date);

        console.log("MODAL DATA!!!!!!", modalData);

        return (
            <Modal show={show} onHide={() => this.handleClose()}>

                <Modal.Header closeButton>
                    <Modal.Title>{modalData.platoonWorking.label.toUpperCase()} - {modalData.dailyDay}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group style={form}>
                            <h3>{formattedDate}</h3>
                            <h4 color={modalData.platoonWorking.color}>People working today</h4>

                            <ul>
                                {
                                    modalData.whosWorkingToday.map(e => (
                                        <li>{e.username}</li>
                                    ))
                                }
                            </ul>
                        </Form.Group>

                        {
                            modalData.trades.length > 0 ? (
                                <Form.Group style={form}>
                                    <h3>Posted Trades</h3>

                                    {
                                        modalData.trades.map((t, i) => ( //loop through all the trades for this day
                                            <div style={{ display: 'flex', width: '80%', textAlign: 'center', alignItems: 'center', justifyContent: 'center', paddingBottom: '10px', flexDirection: 'column' }}>
                                                <p>{t.creator}</p>
                                                <p>Type: {t.type}</p>
                                                <p>Posted on {t.created.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: '2-digit' }).replace(/\//g, '/')}</p>

                                                { //show users how many offers there are on this trade
                                                    t.offers.length > 0 ? (
                                                        <p>{t.offers.length} Offers</p>
                                                    ) : null
                                                }

                                                { //render each offer for the user who created the trade to pick each offer
                                                    (t.creator === user.username) ? (
                                                        <div>
                                                            {
                                                                t.offers.map((o) => (
                                                                    <div>
                                                                        {o.creator}
                                                                        <Button variant="primary" onClick={() => this.acceptOfferOnTrade(i, o.creator)}>Accept Offer</Button>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    ) : null
                                                }

                                                <Button variant="primary" onClick={() => this.cancelOrOfferAgainstTrade(i)}>
                                                    {
                                                        t.creator === user.username ?
                                                            "Cancel Trade" : "Make an Offer"
                                                    }
                                                </Button>
                                            </div>

                                        ))
                                    }

                                </Form.Group>
                            ) : null
                        }


                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {

                        !((modalData.dailyDay === user.dailyOff) || (modalData.platoonWorking.label !== user.platoon) || (modalData.trades.findIndex(t => t.creator === user.username) !== -1)) ?
                            (
                                <>
                                    <Button variant="primary" onClick={() => this.handleCloseAndOpenSubmitTrade("Daily Day")}>
                                        Trade as Daily day
                                    </Button>
                                    <Button variant="primary" onClick={() => this.handleCloseAndOpenSubmitTrade("Platoon Day")}>
                                        Trade as Platoon day
                                    </Button>
                                </>
                            ) : null
                        //if this calenders daily day === his off daily
                        //dont render the trade buttons
                    }
                    <Button variant="secondary" onClick={() => this.handleClose()}>
                        Close
                    </Button>

                </Modal.Footer>

            </Modal>
        )
    }
}

export default FFCalendarDayModal;