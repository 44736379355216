import { Component } from "react";
import { generateFurloughs } from "./FurloughUtils";
import { Button, Modal } from "react-bootstrap";
import { postData } from '../../helpers/postData'

class FurloughManagerPage extends Component
{
    state = {
        //request params
        requestedFurloughs: [],
        users: [],

        //modal
        showModal: false,
        platoonIndex: -1,
        furloughIndex: -1,
        beginningDate: null,
        endingDate: null
    }

    manageFurlough(platoonIndex, furloughIndex, beginningDate, endingDate)
    {
        this.setState({
            showModal: true,
            platoonIndex,
            furloughIndex,
            beginningDate,
            endingDate
        });
    }

    async componentDidMount()
    {
        let r1 = await postData('/getrequestedfurloughs', {});
        let json = await r1.json();
        console.log(json);
        const { requestedFurloughs, users } = json;
        this.setState({ requestedFurloughs, users });
    }

    render()
    {


        const firstPlatoonOrigin = new Date(2022, 0, 12);
        const secondPlatoonOrigin = new Date(2022, 0, 13);
        const thirdPlatoonOrigin = new Date(2022, 0, 11);

        let firstPlatoonGenerated = generateFurloughs(firstPlatoonOrigin);
        let secondPlatoonGenerated = generateFurloughs(secondPlatoonOrigin);
        let thirdPlatoonGenerated = generateFurloughs(thirdPlatoonOrigin);
        let platoons = [firstPlatoonGenerated, secondPlatoonGenerated, thirdPlatoonGenerated];

        return (
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    {
                        platoons.map((p, pi) => (
                            <div style={{ paddingRight: "10%", paddingLeft: "10%" }}>
                                <h4>Platoon {pi + 1}</h4>
                                {
                                    p.map((f, fi) => (
                                        <div style={{ outline: 'solid', padding: '10px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                            <p>Furlough {fi + 1}</p>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <p>{f.startingDate.toLocaleDateString()}</p>
                                                <p style={{ paddingLeft: '5px', paddingRight: '5px' }}> through </p>
                                                <p>{f.endingDate.toLocaleDateString()}</p>
                                            </div>
                                            <Button onClick={() => this.manageFurlough(pi, fi, f.startingDate, f.endingDate)}>Manage Furlough {fi + 1}</Button>
                                        </div>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>

                {
                    this.renderModal()
                }



            </div>
        )
    }

    hardcodeIndexToPlatoon(index)
    {
        switch (index)
        {
            case 0:
                return "PL1";
            case 1:
                return "PL2";
            case 2:
                return "PL3";
            default:
                return null;
        }
    }

    async approveUserForFurlough(username, beginningDate, endingDate)
    {
        this.setState({ showModal: false });
        let rq = await postData('/approveuserforfurlough', { username, beginningDate, endingDate });
        let json = await rq.json();
        console.log("response", json);
    }

    renderModal()
    {
        const { showModal, platoonIndex, furloughIndex, requestedFurloughs, users, beginningDate, endingDate } = this.state;

        if (!showModal)
            return (<></>)

        console.log("state", this.state);

        let wantedPlatoon = this.hardcodeIndexToPlatoon(platoonIndex);
        let foundFurlough = null;
        for (let i = 0; i < requestedFurloughs.length; i++)
        {
            let furlough = requestedFurloughs[i];
            console.log(i, furlough);
            let fbd = new Date(furlough.startingDate);
            let fed = new Date(furlough.endingDate);
            console.log("wanted", beginningDate.getTime(), endingDate.getTime());
            console.log(i, fbd.getTime(), fed.getTime());
            if (furlough.platoon === wantedPlatoon && beginningDate.getTime() === fbd.getTime() && endingDate.getTime() === fed.getTime())
                foundFurlough = furlough;
        }

        console.log("FOUND FURLOUGH: ", foundFurlough);

        let usersRequestinThisFurlough = null;
        if (foundFurlough)
        {
            usersRequestinThisFurlough = users.filter(user => foundFurlough.peopleInterested.find(username => user.username === username));
        }
        console.log("usersRequestinThisFurlough", usersRequestinThisFurlough);


        return (
            <Modal show={showModal} onHide={() => this.setState({ showModal: false })}>

                <Modal.Header closeButton>
                    <Modal.Title>Furlough Manager</Modal.Title>
                </Modal.Header>


                <Modal.Body>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <h4>Platoon {platoonIndex + 1} - Furlough {furloughIndex + 1}</h4>
                    </div>
                    {
                        foundFurlough ? (
                            <div>
                                {
                                    usersRequestinThisFurlough.map(e => (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <p>{e.username}</p>
                                            <Button onClick={() => this.approveUserForFurlough(e.username, beginningDate, endingDate)}>Approve {e.username}</Button>
                                        </div>
                                    ))
                                }

                            </div>
                        ) : (
                            null
                        )
                    }
                </Modal.Body>

                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default FurloughManagerPage;