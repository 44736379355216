import { Component } from "react";

class FourZeroFour extends Component
{
    state = {};

    render()
    {
        return (
            <div>
                <p>this is the 404 page. you must have typed something wrong.</p>
            </div>
        );
    }
}

export default FourZeroFour;