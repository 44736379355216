import { Component } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { postData } from "../../../helpers/postData";

const styles = {
    centerEverything: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column'
    }
}

class RegisterForm extends Component {
    state = {
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        inviteCode: "",

        //relative to page
        showPassword: false
    }

    constructor(props) {
        super(props)
    }

    async sendFormForRegistering()
    {
        let formData = this.state;
        delete formData["showPassword"];
        let r = await postData("/register", formData);
        let response = await r.json();
        console.log("response from backend", response);
        alert(response.message);
    }

    render() {
        const { showPassword } = this.state;

        return (
            <Form onSubmit={(e) => this.fetchUserSubmit(e)}>
                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Image src="/logo192.png" />
                </Form.Group>
                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        id="username"
                        placeholder="Username"
                        onChange={(e) => this.setState({ username: e.target.value })}
                    />
                </Form.Group>

                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="firstName"
                        placeholder="First Name"
                        onChange={(e) => this.setState({ firstName: e.target.value })}
                    />
                </Form.Group>

                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        id="lastName"
                        placeholder="Last Name"
                        onChange={(e) => this.setState({ lastName: e.target.value })}
                    />
                </Form.Group>

                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="text"
                        id="email"
                        placeholder="Email"
                        onChange={(e) => this.setState({ email: e.target.value })}
                    />
                </Form.Group>

                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Form.Label>Password</Form.Label>
                    <div style={{ display: "flex" }}>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            id="password"
                            style={{ paddingRight: "5px" }}
                            onChange={(e) => this.setState({ password: e.target.value })}
                            placeholder="Password"
                        />
                        <Button
                            variant="light"
                            className="password-toggle-button"
                            onClick={() => this.setState({ showPassword: !showPassword })}
                        >{showPassword ? "H" : "S"}</Button>
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Form.Label>Confirm Password</Form.Label>
                    <div style={{ display: "flex" }}>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            id="confirmPassword"
                            style={{ paddingRight: "5px" }}
                            onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                            placeholder="Confirm Password"
                        />
                        <Button
                            variant="light"
                            className="password-toggle-button"
                            onClick={() => this.setState({ showPassword: !showPassword })}
                        >{showPassword ? "H" : "S"}</Button>
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Form.Label>Invite Code</Form.Label>
                    <Form.Control
                        type="text"
                        id="inviteCode"
                        onChange={(e) => this.setState({ inviteCode: e.target.value })}
                        placeholder="Invite Code"
                    />
                </Form.Group>

                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Button onClick={() => this.sendFormForRegistering()}>Register</Button>
                </Form.Group>
            </Form>
        )
    }
}

export default RegisterForm;