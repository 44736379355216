
//const FRONTEND_ORIGIN = "https://firefast.local"; //this is us
//const API_ENDPOINT = `https://api.firefast.local`;

//production
 const FRONTEND_ORIGIN = "https://firefast.app"; //this is us
 const API_ENDPOINT = `https://api.firefast.app`;

export const postData = (url, json) => {
    var formedUrl = `${API_ENDPOINT}${url}`;
    console.log("what does url look like?", formedUrl)
    return fetch(formedUrl, {
        mode: 'cors',
        credentials: 'include',
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Origin': FRONTEND_ORIGIN
        },
        body: JSON.stringify(json)
    });
};

export const postFormData = (url, formData) => {
    var formedUrl = `${API_ENDPOINT}${url}`;
    console.log("what does url look like?", formedUrl)
    return fetch(formedUrl, {
        mode: 'cors',
        credentials: 'include',
        method: "POST",
        headers: {
            'Origin': FRONTEND_ORIGIN
        },
        body: formData
    });
};

export const getData = (url) => {
    var formedUrl = `${API_ENDPOINT}${url}`;
    console.log("what does url look like?", formedUrl)
    return fetch(formedUrl, {
        mode: 'cors',
        credentials: 'include',
        method: "GET",
        headers: {
            'Origin': FRONTEND_ORIGIN
        }
    });
};
