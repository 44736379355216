import { Component } from "react";
import RegisterForm from "./components/RegisterForm";


class RegisterPage extends Component {

    state = {}

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', justifyContent: 'center', alignItems: 'center' }}>
                register page
                <RegisterForm />
            </div>
        )
    }

}

export default RegisterPage;