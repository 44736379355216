import { ListGroup } from "react-bootstrap";
import { postData } from "../../helpers/postData";

const { Component } = require("react");


class RoleEditor extends Component
{
    state = {
        roleMatrix: "",
        roles: []
    }

    async componentDidMount()
    {
        let r1 = await postData('/getroles', {});
        let roles = await r1.json();
        roles = roles.map(e => ({ ...e, deleted: false, original: true }));

        this.setState({ roles });
    }

    submitNewRole()
    {
        const { roleMatrix, roles } = this.state;
        if (roleMatrix.length < 1)
            return;

        let foundRole = roles.findIndex(e => e === roleMatrix)
        if (foundRole !== -1)
        {
            if (foundRole.deleted === true)
            {
                roles[foundRole].deleted = false;
                this.setState({ roles })
                return;
            }
            alert("Role already exists");
            this.setState({ roleMatrix: "" });
            return;
        }

        roles.push(roleMatrix);
        this.setState({ roles, roleMatrix: "" });
    }

    deleteThisRole(index)
    {
        const { roles } = this.state;
        roles.splice(index, 1);
        this.setState({ roles }); //i was last doing this b4 leaving, not plugged into code yet
    }

    render()
    {
        const { roleMatrix, roles } = this.state;

        return <div style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", height: "84vh" }}>
            <p>role editor page</p>

            <div style={{ display: "flex", flexDirection: "column" }}>
                <p>input new role</p>
                <input type="text" value={roleMatrix} placeholder="Role Name Here" onChange={(e) => this.setState({ roleMatrix: e.target.value })} />
                <input type="button" value="Add Role" onClick={() => this.submitNewRole()} />
            </div>

            <div style={{ paddingTop: "30px" }}>
                <p>Currently Existing Roles</p>
                <ListGroup>
                    {
                        roles.filter(e => e.deleted === false).map((e, i) => (
                            <ListGroup.Item action>
                                {e.label}
                            </ListGroup.Item>
                        ))
                    }
                </ListGroup>
            </div>

            <div style={{ paddingTop: "30px" }}>
                <input type="button" value="Update Roles To Server" />
            </div>
        </div>
    }
}

export default RoleEditor;