import { Component } from "react";
import { postData } from "../../helpers/postData";
import { Button, Form, Modal, ListGroup, FormGroup, FormLabel, FormCheck } from "react-bootstrap";



class PlatoonRotation extends Component
{
    state = {
        rotations: [],
        selectedRotation: -1,
        dateMatrix: null,
        rotationMatrix: [],
        dailyMatrix: -1,
        platoons: [],

        modalRotationText: "",
        modalShow: false
    };

    getUser;

    constructor(props)
    {
        super(props);
        this.getUser = props.getUser;
    }

    async componentDidMount()
    {
        await this.resetRotationsToServer();
    }

    async uploadChangesToServer()
    {
        const { rotations } = this.state;
        let modifiedRotations = rotations.filter(r => r.modified || r.deleted);
        console.log("modifiedrotations", modifiedRotations);

        let cleaned = modifiedRotations.map(e => (({ fromServer, modified, ...o }) => o)(e));
        console.log("cleaned", cleaned);

        var r1 = await postData('/updateplatoonrotations', cleaned);
        var j1 = await r1.json();
        j1 = j1.map(o => ({ ...o, fromServer: true, modified: false, deleted: false }));

        console.log("j1 new", j1);

        this.setState({ rotations: j1, selectedRotation: -1, rotationMatrix: [] });
    }

    async resetRotationsToServer()
    {
        var r1 = await postData('/getplatoonrotations', {});
        var j1 = await r1.json();
        j1 = j1.map(o => ({ ...o, fromServer: true, modified: false, deleted: false }));

        var r2 = await postData('/getplatoons?labelsOnly=true', {})
        var j2 = await r2.json();

        console.log("j1 reset", j1);

        this.setState({ rotations: j1, platoons: j2, selectedRotation: -1, rotationMatrix: [], dailyMatrix: -1 });
    }

    formLabelEvent(e)
    {
        var string = e.target.value;
        string = string.replace(/[^\w\s]|_/gi, "");
        if (string.length > 5)
            string = string.substring(0, 5);
        string = string.toUpperCase();
        this.setState({ modalRotationText: string })
    }

    addPlatoonOntoMatrix(index)
    {
        const { rotations, selectedRotation, rotationMatrix, platoons } = this.state;

        if (selectedRotation === -1 || platoons.length === 0)
            return;

        var wantedPlatoon = platoons[index];
        console.log("wantedplatoon", wantedPlatoon);

        rotationMatrix.push(wantedPlatoon.label);

        rotations[selectedRotation].modified = true;

        this.setState({ rotations, rotationMatrix });
    }

    deleteItemInMatrix(index)
    {
        const { rotations, selectedRotation, rotationMatrix } = this.state;
        rotationMatrix.splice(index, 1);

        if (selectedRotation !== -1)
            rotations[selectedRotation].modified = true;

        this.setState({ rotationMatrix });
    }

    modifyDailyMatrix(e)
    {
        const { selectedRotation, rotations } = this.state;

        let rot = rotations[selectedRotation];
        rot.modified = true;
        rot.dailys = parseInt(e.target.value);

        if (rot.dailys < 1)
            rot.dailys = 1;

        this.setState({ dailyMatrix: rot.dailys, rotations });
    }

    openRotation(index)
    {
        const { rotations } = this.state;
        var selectedRot = rotations[index];
        this.setState({
            rotationMatrix: selectedRot.cycle,
            dailyMatrix: selectedRot.dailys,
            selectedRotation: index
        });
    }

    async createNewRotation()
    {
        this.setState({ modalShow: true });
    }

    saveNewRotation()
    {
        const { rotations, modalRotationText } = this.state;
        var newObj = {
            label: modalRotationText,
            cycle: [],
            dailys: 1,
            fromServer: false,
            modified: true,
            deleted: false
        }
        let index = rotations.push(newObj) - 1;
        let cycle = rotations[index].cycle
        let dailys = rotations[index].dailys
        this.setState({ rotations, modalShow: false, modalRotationText: "", selectedRotation: index, rotationMatrix: cycle, dailyMatrix: dailys });
    }

    deleteSelectedRotation()
    {
        const { rotations, selectedRotation } = this.state;

        let rot = rotations[selectedRotation];
        if (rot.fromServer)
            rot.deleted = true;
        else
            rotations.splice(selectedRotation, 1);
        this.setState({ rotations, selectedRotation: -1 });
    }

    closeModal()
    {
        this.setState({ modalShow: false, modalRotationText: "" });
    }

    render()
    {
        const { rotations, rotationMatrix, dailyMatrix, dateMatrix, selectedRotation, platoons, modalShow, modalRotationText } = this.state;

        console.log("render", this.state);

        return (

            <div style={{ display: "flex", flexDirection: "column", gap: "10px", justifyContent: "center" }}>

                <input type="button" value="Reset Changes (from Server)" onClick={() => this.resetRotationsToServer()} style={{ alignSelf: "center", width: "50%" }} />
                <input type="button" value="Upload Changes To Server" onClick={() => this.uploadChangesToServer()} style={{ alignSelf: "center", width: "50%" }} />

                <div style={{ display: "flex", flexDirection: "row" }}>

                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "40%", textAlign: "center", paddingLeft: "5%", paddingRight: "5%" }}>
                        <p>Rotations</p>
                        <ListGroup>
                            {
                                rotations.filter(r => r.deleted === false).length > 0 ?
                                    rotations.filter(r => r.deleted === false).map((e, i) => (<ListGroup.Item key={"rot" + i} action onClick={() => this.openRotation(i)}>{e.label}{e.modified ? "*" : ""}</ListGroup.Item>)) :
                                    <ListGroup.Item>Empty</ListGroup.Item>
                            }
                            <ListGroup.Item action onClick={() => this.createNewRotation()}>Create Platoon Rotation</ListGroup.Item>
                        </ListGroup>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "40%", textAlign: "center", paddingLeft: "5%", paddingRight: "5%" }}>
                        <p>Rotation Editor</p>


                        <Form style={{ paddingBottom: '15px' }}>
                            <FormGroup>
                                <FormLabel>Origin Date</FormLabel>
                                <Form.Control type="date" disabled={dateMatrix === null} />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Repeat Dailys</FormLabel>
                                <Form.Control type="number" disabled={dailyMatrix === -1} min={1} value={dailyMatrix} onChange={(e) => this.modifyDailyMatrix(e)}></Form.Control>
                            </FormGroup>
                        </Form>

                        <p>Rotation</p>
                        <ListGroup>
                            {
                                rotationMatrix.length > 0 ?
                                    rotationMatrix.map((e, i) => <ListGroup.Item key={"matrix" + i} action onClick={() => this.deleteItemInMatrix(i)}> <p>{e}</p> </ListGroup.Item>) :
                                    <ListGroup.Item>{selectedRotation === -1 ? "No Rotation Selected" : "Empty"}</ListGroup.Item>
                            }
                        </ListGroup>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "40%", textAlign: "center", paddingLeft: "5%", paddingRight: "5%" }}>
                        <p>Platoons to pick from</p>
                        <ListGroup>
                            {
                                platoons.length > 0 ?
                                    platoons.map((e, i) => (<ListGroup.Item key={"platoon" + i} action onClick={() => this.addPlatoonOntoMatrix(i)}>{e.label}</ListGroup.Item>)) :
                                    <ListGroup.Item>Empty</ListGroup.Item>
                            }
                        </ListGroup>
                    </div>

                </div>

                {
                    selectedRotation !== -1 ? (<input type="button" value="Delete Selected Rotation" onClick={() => this.deleteSelectedRotation()} style={{ alignSelf: "center", width: "50%" }} />) : null
                }


                <Modal show={modalShow} onHide={() => this.closeModal()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create New Rotation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="platoonEditor" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "10px" }}>
                            <Form.Group>
                                <Form.Label>Rotation Abbreviation</Form.Label>
                                <Form.Control type="text" placeholder="Label" value={modalRotationText} onChange={(e) => this.formLabelEvent(e)}></Form.Control>
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.closeModal()}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => this.saveNewRotation()}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>



            </div >
        )
    }

}

export default PlatoonRotation;