import { Component } from "react";

import { Form, Button, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";

class AdvertisementBox extends Component {

    state = {};

    render()
    {
        return (
            <div style={{maxHeight: "100px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Image style={{maxHeight: "100px", width: "auto\\9", display: "flex"}} src="/placeholderad.png"></Image>
            </div>
        )
    }

}

export default AdvertisementBox;