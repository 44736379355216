import { Component } from "react";
import { Form, Button, Image } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import "bootstrap/dist/css/bootstrap.min.css";

import { postData } from "../../../helpers/postData";

const styles = {
    centerEverything: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column'
    }
}

class LoginForm extends Component {
    state = {
        username: null,
        password: null,
        showPassword: false
    };

    setUser = null;
    captchaResponse = null;

    constructor(props) {
        super(props)
        console.log("loginForm props", props);
        this.setUser = props.setUser;
    }

    fetchUserSubmit(e) {
        e.preventDefault();
        this.fetchUser();
    }

    async fetchUser() {
        const { username, password } = this.state;
        const { captchaResponse } = this;
        var request = await postData('/login', { username, password, "g-recaptcha-response": captchaResponse });
        var json = await request.json();
        if (json.success)
            this.setUser(json.success ? json.user : null);
        console.log("loginform fetch json", json);
    }

    render() {
        const { showPassword } = this.state;

        return (
            <Form onSubmit={(e) => this.fetchUserSubmit(e)}>
                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Image src="/logo192.png" />
                </Form.Group>
                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" id="username" placeholder="username" onChange={(e) => this.setState({ username: e.target.value })} />
                </Form.Group>

                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Form.Label>Password</Form.Label>
                    <div style={{ display: "flex" }}>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            id="password"
                            style={{ paddingRight: "5px" }}
                            onChange={(e) => this.setState({ password: e.target.value })}
                        />
                        <Button
                            variant="light"
                            className="password-toggle-button"
                            onClick={() => this.setState({ showPassword: !showPassword })}
                        >{showPassword ? "H" : "S"}</Button>
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <ReCAPTCHA
                        sitekey="6Lffz6ApAAAAAJ72makn0HUVbaQUZsfQGZDcZqPC"
                        onChange={(value) => this.captchaResponse = value}
                    />
                </Form.Group>

                <Form.Group className="mb-3" style={styles.centerEverything}>
                    <Button onClick={() => this.fetchUser()}>Login</Button>
                </Form.Group>
            </Form>
        )
    }
}

export default LoginForm;
