import { Component } from "react";
import { SketchPicker } from "react-color";

import { Button, Form, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import { postData } from "../../helpers/postData";

import "./PlatoonBuilder.css";

import ListGroup from 'react-bootstrap/ListGroup';


class PlatoonBuilder extends Component
{

    state = {
        grabbedPlatoons: [],
        platoonLabel: "",
        platoonTitle: "",
        platoonColor: "#fff",
        platoonMembers: [],
        platoonCompanies: [],
        modalShow: false,
        modifyingPlatoon: -1
    };

    getUser = null;

    constructor(props)
    {
        super(props)
        this.getUser = this.props.getUser;
    }

    componentDidMount()
    {
        this.resetFromServer();
    }

    async fetchServerData()
    {
        var request = await postData("/getplatoons", {});
        var json = await request.json();
        return json;
    }

    async resetFromServer()
    {
        var json = await this.fetchServerData();
        this.deserializePlatoons(json);
    }

    deserializePlatoons(json)
    {
        this.setState({ grabbedPlatoons: json.map(j => ({ ...j, modified: false, deleted: false })) });
    }

    formLabelEvent(e)
    {
        var string = e.target.value;
        string = string.replace(/[^\w\s]|_/gi, "");
        if (string.length > 5)
            string = string.substring(0, 5);
        string = string.toUpperCase();
        this.setState({ platoonLabel: string })
    }

    formTitleEvent(e)
    {
        var string = e.target.value;
        string = string.replace(/[^\w\s]|_/gi, "");
        if (string.length > 20)
            string = string.substring(0, 20);
        string = string.replace(/\b\w/g, c => c.toUpperCase());
        this.setState({ platoonTitle: string });
    }

    openModal(index)
    {
        console.log("index:", index);
        if (isNaN(index) || index === null || index === undefined)
        {
            this.setState({
                platoonLabel: "",
                platoonTitle: "",
                platoonColor: "#fff",
                modalShow: true,
                modifyingPlatoon: -1,
                platoonMembers: [],
                platoonCompanies: [],
            });
            return;
        }

        if (!isNaN(index) && index < this.state.grabbedPlatoons.length)
        {
            var platoonToModify = this.state.grabbedPlatoons[index];
            this.setState({
                platoonLabel: platoonToModify.label,
                platoonTitle: platoonToModify.title,
                platoonColor: platoonToModify.color,
                platoonMembers: platoonToModify.members,
                platoonCompanies: platoonToModify.companies,
                modalShow: true,
                modifyingPlatoon: index
            });
        }
        console.log(this.state);
    }

    toggleDeletePlatoon(index)
    {
        if (!isNaN(index) && index < this.state.grabbedPlatoons.length)
        {
            const { grabbedPlatoons } = this.state;
            grabbedPlatoons[index].deleted = !grabbedPlatoons[index].deleted;
            grabbedPlatoons[index].modified = true;
            this.setState({ grabbedPlatoons });
        }
    }

    addPlatoonMember()
    {
        const { modifyingPlatoon, grabbedPlatoons } = this.state;

        if (modifyingPlatoon > -1 && modifyingPlatoon < grabbedPlatoons.length)
        {
            const strGiven = window.prompt("Username to add");
            console.log(strGiven);
            if (strGiven != null && strGiven.length > 0)
            {
                grabbedPlatoons[modifyingPlatoon].members.push(strGiven);
                this.setState({ grabbedPlatoons });
            }
        }
    }

    handleColorChange(color)
    {
        this.setState({ platoonColor: color.hex });
    }

    handleModalClose()
    {
        this.setState({ modalShow: false, modifyingPlatoon: -1 });
    }

    saveChangesModal()
    {
        const { grabbedPlatoons, modifyingPlatoon, platoonLabel, platoonTitle, platoonColor, platoonMembers, platoonCompanies } = this.state;

        var asObj = {
            label: platoonLabel.trim(),
            title: platoonTitle.trim(),
            color: platoonColor,
            members: platoonMembers,
            companies: platoonCompanies,
            modified: true,
            deleted: false
        }

        if (modifyingPlatoon !== -1)
        {
            var oldLabel = grabbedPlatoons[modifyingPlatoon].label;
            if (oldLabel !== platoonLabel)
            {
                grabbedPlatoons[modifyingPlatoon].modified = true;
                grabbedPlatoons[modifyingPlatoon].deleted = true;
                grabbedPlatoons.push(asObj);
            }
            else
            {
                grabbedPlatoons[modifyingPlatoon] = asObj;
            }
        }
        else
            grabbedPlatoons.push(asObj);

        this.handleModalClose();
    }

    async updatePlatoonsOnServer()
    {
        const { grabbedPlatoons } = this.state;
        var modifiedPlatoons = grabbedPlatoons.filter(p => p.modified).map(({ modified, ...rest }) => rest);
        console.log(modifiedPlatoons);

        var request = await postData("/updateplatoons", modifiedPlatoons);
        var json = await request.json();
        this.deserializePlatoons(json);
        console.log("updateplatoons", json);
    }

    renderPlatoonList()
    {
        const { grabbedPlatoons, showDeletedPlatoons } = this.state;
        var platoons = grabbedPlatoons;
        if (!showDeletedPlatoons)
            platoons = platoons.filter(p => !p.deleted);
        return platoons.map((e, i) => (

            <tr key={"tr_" + i} style={{ backgroundColor: e.deleted ? "gray" : null }}>
                <td>
                    <div>
                        <div style={{ aspectRatio: "1/1", width: "25px", height: "25px", backgroundColor: e.color }} onClick={() => this.openModal(i)}></div>
                        <p style={{ paddingLeft: "10px" }}>{e.label}</p>
                    </div>

                </td>
                <td>
                    <p>{e.title}{e.modified ? "*" : ""}{e.deleted ? " (Deleted)" : ""}</p>
                </td>
                <td>
                    <input type="button" key={"edit" + e.title} value="Edit" onClick={() => this.openModal(i)} />
                </td>
                <td>
                    <input type="button" key={"delete" + e.title} value={e.deleted ? "Restore" : "Delete"} onClick={() => this.toggleDeletePlatoon(i)} />
                </td>
            </tr>
        ));
    }

    render()
    {
        var user = this.getUser();
        if (!user || !user.admin)
            return (<div>You must be logged in as an administrator to see this page</div>);

        const { platoonLabel, platoonTitle, platoonColor, platoonMembers, modalShow, grabbedPlatoons, showDeletedPlatoons } = this.state;
        console.log("grabbedplatoons from render", grabbedPlatoons);
        console.log("state", this.state);

        return (
            <div>

                <p>i am the platoon builder page</p>

                <input type="button" value="New Platoon" onClick={() => this.openModal(null)} />
                <input type="button" value="Update Platoons On Server" onClick={() => this.updatePlatoonsOnServer()} />
                <input type="button" value="Reset Changes (Reload from Server)" onClick={() => this.resetFromServer()} />


                <input type="checkbox" id="deletedPlatoonsCheckbox" value={showDeletedPlatoons} checked={showDeletedPlatoons} onChange={() => this.setState({ showDeletedPlatoons: !showDeletedPlatoons })} />
                <label for="deletedPlatoonsCheckbox">Show Deleted Platoons</label>

                <div className="pbdiv">
                    <table className="pbtable">
                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>Label</th>
                                <th style={{ width: "100%" }}>Title</th>
                                <th style={{ width: "10%" }}>Edit</th>
                                <th style={{ width: "10%" }}>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.renderPlatoonList()
                            }
                        </tbody>
                    </table>


                </div>


                <Modal show={modalShow} onHide={() => this.handleModalClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editing {platoonTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form id="platoonEditor" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", paddingTop: "10px" }}>
                            <Form.Group>
                                <Form.Label>Label</Form.Label>
                                <Form.Control type="text" placeholder="Label" value={platoonLabel} onChange={(e) => this.formLabelEvent(e)}></Form.Control>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Title</Form.Label>
                                <Form.Control type="text" placeholder="Platoon Title" value={platoonTitle} onChange={(e) => this.formTitleEvent(e)}></Form.Control>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Platoon Color</Form.Label>
                                <SketchPicker color={platoonColor} disableAlpha={true} onChange={(color) => this.handleColorChange(color)} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Members</Form.Label>
                                <ListGroup>
                                    {
                                        platoonMembers.map(username => (<ListGroup.Item key={username}>{username}</ListGroup.Item>))
                                    }
                                    <ListGroup.Item><input key={"addMember" + platoonTitle} type="button" value="Add Member" onClick={() => this.addPlatoonMember()} /></ListGroup.Item>
                                </ListGroup>
                            </Form.Group>

                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleModalClose()}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => this.saveChangesModal()}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default PlatoonBuilder;