import { Component } from "react";
import FFCalendarCell from "./FFCalendarCell";
import Table from 'react-bootstrap/Table';


class FFCalendarTable extends Component {
    state = {
        cellData: [],
        navigationText: "Navigate"
    }
    clickHandler;
    seekHandler;

    constructor(props) {
        super(props)
        this.clickHandler = props.clickHandler;
        this.seekHandler = props.seekHandler;
    }

    componentDidMount() {
        const { cellData, navigationText } = this.props;
        this.setState({ cellData, navigationText })
    }

    componentDidUpdate(prevProps) {
        const { cellData, navigationText } = this.props;
        if (cellData !== prevProps.cellData)
            this.setState({ cellData, navigationText })
    }

    //EVENT: CLICKEVENT
    handleCell(e, i) {
        if (this.clickHandler)
            this.clickHandler(e, i);
    }

    //EVENT: SEEKING
    handleSeek(left) {
        if (this.seekHandler)
            this.seekHandler(left);
    }

    //RENDERING: ROW BUILDING
    buildRows() {
        const { cellData } = this.state;
        var rows = []

        if (!cellData)
            return rows;

        for (var r = 0; r < 6; r++) {
            var beginningIndex = r * 7
            var endingIndex = r * 7 + 7
            var sliced = cellData.slice(beginningIndex, endingIndex);

            //they will all share the same row number if it isn't const
            const finalR = r;

            rows.push((
                <tr key={finalR}>
                    {
                        sliced.map((e, i) => (
                            <td key={finalR + i} className={e.placeholder ? "ffPlaceholderCell" : null} style={{ width: "14.2857%", height: "17.5%", padding: "0", margin: "0", boxSizing: "border-box", outline: e.outline, outlineColor: e.outlineColor }} >
                                <FFCalendarCell key={"cell_" + (finalR + i)} cellInfo={e} onClick={() => this.handleCell(e, (finalR * 6) + finalR + i)} />
                            </td>
                        ))
                    }
                </tr>
            ))
        }

        return rows;
    }

    //RENDERING: TABLE BUILDING (USES ROW BUILDING)
    renderTable() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <Table striped bordered hover>
                    <thead>
                        <tr className="abbreviated">
                            <th>Sun</th>
                            <th>Mon</th>
                            <th>Tue</th>
                            <th>Wed</th>
                            <th>Thu</th>
                            <th>Fri</th>
                            <th>Sat</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.buildRows()
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    //RENDERING: NAVIGATION SEEKER
    renderNavigationSeeker() {
        if (!this.seekHandler)
            return (<></>)

        const { navigationText } = this.state;

        return (
            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', alignContent: 'center', gap: '10px', paddingBottom: '10px' }}>
                <input type="button" value="<" onClick={() => this.handleSeek(true)} />
                <p>{navigationText}</p>
                <input type="button" value=">" onClick={() => this.handleSeek(false)} />
            </div>
        )
    }

    //RENDERING
    render() {
        return (<>
            {
                this.renderNavigationSeeker()
            }
            {
                this.renderTable()
            }
        </>)
    }
}

export default FFCalendarTable;