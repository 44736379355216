import { Component } from "react";
import FFProgrammedCalendar from "./components/FFProgrammedCalendar";
import { postData } from "../../helpers/postData";
import FFCalendarDayModal from "./components/FFCalendarDayModal";
import { distanceInDaysBetweenDates, getLetterCombination } from "../../helpers/CalendarUtils";
import FFCalendarConfirmTradeModal from "./components/FFCalendarConfirmTradeModal";


class FFCalendarPage extends Component {

    state = {
        //old calendar design
        platoonOrigin: new Date(2020, 11, 18), //when the platoon rotation officially starts (there is no end) HARDCODE
        emsOrigin: new Date(2020, 11, 17), //HARDCODE
        date: new Date(), //grabs today, which then the calendar sees the month of today, and generates the calendar for todays month
        show: false,
        modalData: null,
        rotationInformation: null,
        platoons: [],
        showSubmitTrade: false,
        tradeType: null,
        trades: [],
        myRequestedFurloughs: []
    }

    getUser;

    constructor(props) {
        super(props)
        this.getUser = props.getUser;
    }

    //COMPONENT DID MOUNT
    async componentDidMount() {
        console.log("FFPROGRAMMED COMPONENT MOUNT!");
        let r1 = await postData('/getplatoons', {})
        let platoons = await r1.json();
        console.log("platoons", platoons);
        let r2 = await postData('/gettradesforthismonth', { date: this.state.date });
        let tradeObj = await r2.json();
        console.log("tradeObj", tradeObj);

        let newState = {
            platoons,
            trades: []
        };

        //trades
        if (tradeObj.trades) {
            newState.trades = tradeObj.trades;
            for (let i = 0; i < newState.trades.length; i++)
                newState.trades[i].date = new Date(newState.trades[i].date);
        }

        //furloughs
        let r3 = await postData('/currentlyrequestedfurloughs', {});
        let furloughJson = await r3.json();
        for (let i = 0; i < furloughJson.requestedFurloughs.length; i++) {
            furloughJson.requestedFurloughs[i].startingDate = new Date(furloughJson.requestedFurloughs[i].startingDate)
            furloughJson.requestedFurloughs[i].endingDate = new Date(furloughJson.requestedFurloughs[i].endingDate)
        }
        newState.myRequestedFurloughs = furloughJson.requestedFurloughs;

        console.log("newState", newState);

        this.setState(newState);
    }

    //CALENDAR SEEKING METHOD (FROM OLD CALENDAR) (It needs to be in the page since this is modifying the state variables)
    async seekCurrentMonthOfCalender(forward) {
        const { date } = this.state;
        let d8 = new Date(date.getTime());
        d8.setMonth(d8.getMonth() + (forward ? 1 : -1));

        let r2 = await postData('/gettradesforthismonth', { date: d8 });
        let trades = await r2.json();
        console.log("trades", trades);

        let r3 = await postData('/currentlyrequestedfurloughs', {});
        let furloughJson = await r3.json();
        //convert string dates into date objects
        for (let i = 0; i < furloughJson.requestedFurloughs.length; i++) {
            furloughJson.requestedFurloughs[i].startingDate = new Date(furloughJson.requestedFurloughs[i].startingDate)
            furloughJson.requestedFurloughs[i].endingDate = new Date(furloughJson.requestedFurloughs[i].endingDate)
        }
        let furloughs = furloughJson.requestedFurloughs;
        console.log("FURLOUGHS???", furloughs);


        let newState = {
            date: d8,
            trades: []
        };
        if (trades.trades) {
            newState.trades = trades.trades;
            for (let i = 0; i < newState.trades.length; i++)
                newState.trades[i].date = new Date(newState.trades[i].date);
        }


        this.setState(newState);
    }

    //CALENDER (THEY CLICKED A DAY, OPEN UP THE MODAL TO SHOW INFORMATION ON THAT DAY)
    async showWorkDayOnModal(date) {
        if (!this.getUser())
            return;
        if (!date)
            return;

        const { platoonOrigin, emsOrigin, platoons } = this.state;
        console.log("working day modal called!", date)

        let distanceBetweenPlatoonOrigin = distanceInDaysBetweenDates(platoonOrigin, date);
        let distanceBetweenEmsOrigin = distanceInDaysBetweenDates(emsOrigin, date);

        let dailyDay = getLetterCombination((Math.floor(distanceBetweenPlatoonOrigin / 3) % 5) + 1);
        let platoonLoop = platoons[distanceBetweenPlatoonOrigin % 3];

        console.log(dailyDay);
        console.log(platoonLoop);

        let r1 = await postData('/whosworkingtoday', { isodate: date.toISOString() });
        let whosWorkingToday = await r1.json();
        whosWorkingToday = whosWorkingToday.peopleWorkingToday;
        console.log("WHOS WORKING TODAY", whosWorkingToday);

        let r2 = await postData('/requesttradesforcalenderday', { date });
        let tradeObj = await r2.json();
        console.log("TRADES TODAY", tradeObj);
        for (let i = 0; i < tradeObj.trades.length; i++) {
            tradeObj.trades[i].date = new Date(tradeObj.trades[i].date);
            tradeObj.trades[i].created = new Date(tradeObj.trades[i].created);
        }


        const modalData = {
            date,
            dailyDay,
            platoonWorking: platoonLoop,
            whosWorkingToday,
            trades: tradeObj.trades
        }

        this.setState({ show: true, modalData });
    }

    //make it show the trade on the calendar instantly
    async submitTradeToTradeBank() {
        const { tradeType, modalData } = this.state;
        const { date } = modalData;
        const user = this.getUser();

        let r1 = await postData('/submittrade', {
            tradeType,
            date
        });
        let json = await r1.json();
        console.log("response from server", json);

        let newState = { showSubmitTrade: false };

        //rerender the trade on the respective calender cell without refreshing the page
        if (json.message === "Trade Completed!") {
            let trades = this.state.trades;
            trades.push({
                creator: user.username,
                date: modalData.date
            });
            newState.trades = trades;
        }

        this.setState(newState);
        this.showWorkDayOnModal(date);
    }

    async cancelOrOfferAgainstTrade(tradeIndex) {
        const { modalData } = this.state;
        const tradeWanted = modalData.trades[tradeIndex];
        // const user = this.getUser();
        console.log("TradeWanted", tradeWanted);

        const { date, type, creator } = tradeWanted;

        let r1 = await postData('/interactwithtrade', { date, type, creator });
        let json = await r1.json();
        console.log("Interacting with trade message", json);

        //here we gotta remove the trade out of the state's trade array
        if (json.message === "Removed") {
            console.log("REMOVED REMOVED REMOVED LOOP");
            let user = this.getUser();
            let trades = this.state.trades;
            trades = trades.filter(t => !(t.date.getTime() === date.getTime() && user.username === t.creator));
            console.log("TRADES FILTERED", trades);
            this.setState({ trades });
        }


        this.showWorkDayOnModal(date);
    }

    //RENDERING
    render() {
        return (
            <div>
                <h1>THE CALENDAR PAGE</h1>
                <FFProgrammedCalendar
                    getUser={() => this.getUser()}
                    getParentState={() => this.state}
                    seekCurrentMonthOfCalender={(forward) => this.seekCurrentMonthOfCalender(forward)}
                    showWorkDayOnModal={(date) => this.showWorkDayOnModal(date)}
                />
                <FFCalendarDayModal
                    getUser={() => this.getUser()}
                    getParentState={() => this.state}
                    handleClose={() => this.setState({ show: false })}
                    setModalData={(modalData) => this.setState({ show: true, modalData })}
                    handleCloseAndOpenSubmitTrade={(tradeType) => this.setState({ show: false, showSubmitTrade: true, tradeType })}
                    handleSubmitTradeClose={() => this.setState({ showSubmitTrade: false })}
                    cancelOrOfferAgainstTrade={(tradeIndex) => this.cancelOrOfferAgainstTrade(tradeIndex)}
                />
                <FFCalendarConfirmTradeModal
                    getUser={() => this.getUser()}
                    getParentState={() => this.state}
                    submitTradeToTradeBank={() => this.submitTradeToTradeBank()}
                />
            </div>
        )
    }
}

export default FFCalendarPage;