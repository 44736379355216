

export const distanceInDaysBetweenDates = (d1, d2) =>
{
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    return Math.round(Math.abs((d1.getTime() - d2.getTime()) / oneDay));
}

export const generateFurloughs = (originDate) =>
{
    let list = [];

    //lets get the next year from origin date
    let nextYear = new Date(originDate.getTime());
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    nextYear.setMonth(0);
    nextYear.setDate(1);

    //furloughs are 16 days but they overlap the next one by 1 day, so divide by 15
    let furloughsInYear = Math.ceil(distanceInDaysBetweenDates(nextYear, originDate) / 15);

    //loop through furloughs
    for (let i = 0; i < furloughsInYear; i++)
    {
        let startingDate = new Date(originDate.getTime());
        startingDate.setDate(startingDate.getDate() + (i * 16) - i);
        let endingDate = new Date(startingDate.getTime());
        endingDate.setDate(endingDate.getDate() + 16);
        list.push({ startingDate, endingDate });
    }

    return list;
}