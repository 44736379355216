import { Component } from "react";
import { postData } from "../../helpers/postData";

class TestPageThree extends Component
{
    state = {}

    async componentDidMount()
    {
        let r1 = await postData('/woah', { rotations: ["ROT1", "EMT"], month: 3, year: 2023 });
        let json = await r1.json();
        console.log("response", json);
    }

    render()
    {
        return (
            <div>

                test page 3

                {
                    Array.from({ length: 3 }).map((_, i) => (<p>{i}</p>))
                }


            </div>
        );
    }
}

export default TestPageThree;