




//UTILITY METHODS (PLEASE EXPORT THESE ELSEWHERE!)
export const getLastDayOfMonth = (date) => {
    var clonedDate = new Date(date.getTime());
    clonedDate.setDate(1);
    clonedDate.setMonth(clonedDate.getMonth() + 1);
    clonedDate.setDate(clonedDate.getDate() - 1);
    return clonedDate.getDate();
}

export const distanceInDaysBetweenDates = (d1, d2) => {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    return Math.round(((d2.getTime() - d1.getTime()) / oneDay));
}

export const getLetterCombination = (num) => {
    let result = "";
    while (num > 0) {
        num--;
        result = String.fromCharCode(num % 26 + 65) + result;
        num = Math.floor(num / 26);
    }
    return result;
}