import React, { Component } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

class ComboBox extends Component
{
    state = {}

    constructor(props)
    {
        super(props);
        this.state = {
            selectedOption: props.selectedOption,
        };
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps.selectedOption !== this.props.selectedOption)
        {
            this.setState({ selectedOption: this.props.selectedOption });
        }
    }

    handleSelect = (option) =>
    {
        this.setState({ selectedOption: option });
        this.props.onSelect(option);
    };

    render()
    {
        const { options, id } = this.props;
        const { selectedOption } = this.state;

        let generatedId = id;
        if (!generatedId)
            generatedId = "combo-box" + (Math.floor(Math.random() * 100000) + 1);


        return (
            <DropdownButton
                id={generatedId}
                title={selectedOption || 'Select an option'}
                onSelect={this.handleSelect}
            >
                {options.map((option, index) => (
                    <Dropdown.Item key={index} eventKey={option}>
                        {option}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        );
    }
}

export default ComboBox;